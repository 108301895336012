import React, { useEffect } from "react";

import { json, useLoaderData, useLocation } from "react-router-dom";
import App from "./App";
import Skillnow from "./pages/News/Skillnow";
import Anniversary_16th from "./pages/News/Anniversary-16th";
import Dreamfox from "./pages/News/Dreamfox";

import Cohering from "./pages/News/Cohering";
import Leads from "./pages/fugxnfi7kpkIgQg+T6T5xQ/fugxnfi7kpkIgQg+T6T5xQ";
// import W_digital from "./pages/W_Digital/W_digital";
// import Wdigital_program from "./pages/W_Digital/Wdigital_program";
// import Talentare from "./pages/Talentare/Talentare";
// import IT from "./pages/IT/IT";
// import Veracis from "./pages/Veracis/Veracis";
// import Vivacis from "./pages/Vivacis/Vivacis";
// import Consultare_engineering_services from "./pages/Consultare/Consultareengineeringservices";
// import Cloud from "./pages/Cloud/Cloud";
// import Right_apps from "./pages/Right_apps/Rightapps";
// import Oracle from "./pages/Oracle/Oracle";
// // import Salesforce from './pages/Salesforce';
// import Microsoft from "./pages/Microsoft";
// import Creatio from "./pages/Creatio/Creatio";
// import Agiloft from "./pages/Agiloft/Agiloft";
// import Alliances_specialized from "./pages/Alliance/AlliancesSpecialized";
// import Io from "./pages/IO/Io";
// import Ventures from "./pages/Ventures/Ventures";
// import Nexorama_wundero from "./pages/Nexorama/Nexoramawundero";
// import Nexorama_expedience from "./pages/Nexorama/Nexoramaexpedience";
// import Solutions from "./pages/Solution/Solutions";
// import Industry from "./pages/Industry/Industry";
// import Business4_0 from "./pages/Buisness4-0/Business4_0";
// import Ex_digital from "./pages/EX_Digital/Exdigital";
// import Solutions_sales from "./pages/Solution/Solutionssales";
// import Solutions_marketing from "./pages/Solution/Solutionsmarketing";
// import Solutions_service from "./pages/Solution/Solutionsservice";
// import Solutions_commerce from "./pages/Solution/Solutionscommerce";
// import O_digital from "./pages/O_Digital/Odigital";
// import VivacisOracleNetsuite from "./pages/Vivacis/VivacisOracleNetsuite";
// import Customers from "./pages/Customer/Customers";
// import Careers from "./pages/Careers/Careers";
// import News from "./pages/News/News";
// import Corporate_Story from "./pages/Corporate/CorporateStory";
// import Corporate_Leadership from './pages/Corporate/Corporate_Leadership';
// import Corporate_Team from "./pages/Corporate/CorporateTeam";
// import Virtuosoship from "./pages/Virtuosoship";
// import Investors from "./pages/Investors";
// // import Corporate_Vision from './pages/Corporate/Corporate_Vision';
// import Community from "./pages/Community/Community";
// import Corporate_Experience_Edge from "./pages/Corporate/CorporateExperienceEdge";
// import Succeedant from "./pages/Succeedant";
// import Insights from "./pages/Insights/Insights";
// import Customers_Success_Stories from "./pages/CustomersSuccessStories";
// import Careers_Opportunities from "./pages/Careers/Careers_Opportunities";
// // import Careers_Experience_Job from './pages/Careers/Careers_Experience_Job.js';

// // import Customers_Life_At_Virtuos from './pages/Careers/Customers_Life_At_Virtuos';

// import Webinars from "./pages/Webinars/Webinars";
// import Branding from "./pages/Branding/Branding";
// import Consultare_technology_services from "./pages/Consultare/Consultaretechnologyservices";
// import Business3 from "./pages/Business3";
// import Indicial from "./pages/Indicial";
// import Consultare_Applications_Group from "./pages/Consultare/ConsultareApplicationsGroup";
// import Consultare_Crm_Cx from "./pages/Consultare/ConsultareCrmCx";
// import Consultare_Pace_Layered from "./pages/Consultare/ConsultarePaceLayered";
// import Consultare_Platform_Ecosystem from "./pages/Consultare/ConsultarePlatformEcosystem";
// import Consultare_Experience_Design from "./pages/Consultare/ConsultareExperienceDesign";
// import Flipkart_Case_Study from "./pages/Customer/FlipkartCaseStudy";
// import Standard_Chartered_Case_Study from "./pages/Customer/StandardCharteredCaseStudy";
// import Makemytrip_Case_Study from "./pages/Customer/MakemytripCaseStudy";

// import Indiafirst_Case_Study from "./pages/Customer/IndiafirstCaseStudy";
// import Creatio_Crm_For_Power_CO_Case_Study from "./pages/Customer/CreatioCrmForPowerCOCaseStudy";
// import Agiloft_Clm_For_Internet from "./pages/Customer/AgiloftClmForInternet";
// import Tvs_Credit_Case_Study from "./pages/Customer/TvsCreditCaseStudy";
// import ThomasCookInidia from "./pages/Customer/ThomasCookInidia";
// import Entertainment_compnay from "./pages/Customer/Entertainmentcompnay";
// import Myntra from "./pages/Customer/Myntra";
// import Career_Expereince_Job from "./pages/Careers/Career_Expereince_Job";
// import Career_Culture from "./pages/Careers/Career_Culture";
// import Life_at_virtuos from "./pages/Careers/Life_at_virtuos";
// import Training_Developments from "./pages/Careers/Training_Developments";
// import Happiest_culture from "./pages/Careers/Happiest_culture";
// import Employee_Benefit from "./pages/Careers/Employee_Benefit";
// import Outlook from "./pages/Careers/Outlook";
// import Virtuos_Alumni from "./pages/Careers/Virtuos_Alumni";
// import Agilenterprise from "./pages/Buisness4-0/Agilenterprise";
// import All_Agile from "./pages/All-Agile/AllAgile";
// import Invincible from "./pages/Invincible";
// import Vuca from "./pages/Vuca/Vuca";
// import Vuca_Covid from "./pages/Vuca/VucaCovid";
// import Unified_Desk from "./pages/CX_Desk/UnifiedDesk";
// import Customer_Success from "./pages/CX_Desk/CustomerSuccess";
// import Cxopia from "./pages/CX_NOW/Cxopia";
// import Pyramid from "./pages/CX_NOW/Pyramid";

const Cxdatum = React.lazy(() => import("./pages/CX_NOW/Cxdatum"));
const Customer_Journey = React.lazy(() =>
  import("./pages/CX_NOW/Customer_Journey")
);
const Cxprism = React.lazy(() => import("./pages/CX_NOW/Cxprism"));
const Cx_Audit = React.lazy(() => import("./pages/CX_NOW/CxAudit"));
const Customer_Voice = React.lazy(() => import("./pages/CX_NOW/CustomerVoice"));
const CX_Unity = React.lazy(() => import("./pages/CX_NOW/CXUnity"));
const Nexorama = React.lazy(() => import("./pages/Nexorama/Nexorama"));
const Nexorama_strategy = React.lazy(() =>
  import("./pages/News/Nexoramastrategy")
);
const Oracle_nestitute = React.lazy(() => import("./pages/OracleNestitute"));
const Exonomy_Aura = React.lazy(() => import("./pages/Xonomy/ExonomyAura"));
const Expereince_Cloud = React.lazy(() =>
  import("./pages/Expereince_Cloud/ExpereinceCloud")
);
const Expereince_Crowd = React.lazy(() => import("./pages/ExpereinceCrowd"));
const Agnitor = React.lazy(() => import("./pages/Agnitor/Agnitor"));
const Exonomy_Budh = React.lazy(() => import("./pages/Xonomy/ExonomyBudh"));
const Xonomy_Budh = React.lazy(() => import("./pages/Xonomy/XonomyBudh"));
const Xonomy_Ai = React.lazy(() => import("./pages/Xonomy/XonomyAi"));
const Xonomy_Ai_Selservice = React.lazy(() => import("./pages/Xonomy/XonomyAiSelservice"));

const Exonomy_Ai = React.lazy(() => import("./pages/Xonomy/ExonomyAi"));
const Exonomy_Ai_Selservice = React.lazy(() =>
  import("./pages/Xonomy/ExonomyAiSelservice")
);
const Qualtrics = React.lazy(() => import("./pages/Qualtrics/Qualtrics"));
const IoDesignThinking = React.lazy(() =>
  import("./pages/IO/IoDesignThinking")
);
const Exp_new = React.lazy(() => import("./pages/Expnew"));
const Multimix = React.lazy(() => import("./pages/Rainbowcx/Multimix"));
const Continuum = React.lazy(() => import("./pages/CX_NOW/Continuum"));
const Rigour = React.lazy(() => import("./pages/Buisness4-0/Rigour"));
const Customer_Engagement = React.lazy(() =>
  import("./pages/C_Digital/CustomerEngagement")
);
const Expereince_ID = React.lazy(() =>
  import("./pages/C_Digital/ExpereinceID")
);
const Customer_Wide = React.lazy(() =>
  import("./pages/C_Digital/CustomerWide")
);
const Digital_Dragon = React.lazy(() =>
  import("./pages/C_Digital/DigitalDragon")
);
const Customerthree_CI = React.lazy(() =>
  import("./pages/C_Digital/CustomerthreeCI")
);
const Customerthree = React.lazy(() =>
  import("./pages/C_Digital/Customerthree")
);
const Careers_FAq = React.lazy(() => import("./pages/Careers/Careers_FAq"));
const Marries = React.lazy(() => import("./pages/CX/Marries"));
const CX_Digital = React.lazy(() => import("./pages/CX-Digital/CXDigital"));
const Digital = React.lazy(() => import("./pages/Digital/Digital"));
const Digital_New = React.lazy(() => import("./pages/Digital_New/DigitalNew"));
const Digital_Asap = React.lazy(() => import("./pages/Digital/Digital_Asap"));
const Oracle_Service = React.lazy(() =>
  import("./pages/Digital_New/OracleService")
);
const Expereince_Cloud_Aury = React.lazy(() =>
  import("./pages/Expereince_Cloud/ExpereinceCloudAury")
);
const Experienceo = React.lazy(() => import("./pages/Experienceo/Experienceo"));
const Lab = React.lazy(() => import("./pages/IO/Lab"));
const Virtuos = React.lazy(() => import("./pages/IO/Virtuos"));
const O_Continnum = React.lazy(() => import("./pages/O_Digital/OContinnum"));
const O_Digital_Asap = React.lazy(() =>
  import("./pages/O_Digital/ODigitalAsap")
);
const Oracle_Service_Cloud = React.lazy(() =>
  import("./pages/Oracle/OracleServiceCloud")
);
const Privacy_Policy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Safe_Harbour = React.lazy(() => import("./pages/SafeHarbour"));
const Survey = React.lazy(() => import("./pages/Survey/Survey"));
const Terms_Of_Use = React.lazy(() =>
  import("./pages/Term of use/Terms_Of_Use")
);
const Thankyou = React.lazy(() => import("./pages/Thankyou/Thankyou"));
const Viraata = React.lazy(() => import("./pages/Viraata/Viraata"));
const Vivacis_Dp = React.lazy(() => import("./pages/Vivacis/VivacisDpa"));
const Vlocis = React.lazy(() => import("./pages/Vlocis/Vlocis"));
const Zidd = React.lazy(() => import("./pages/Zidd/Zidd"));
const Sweven = React.lazy(() => import("./pages/Sweven"));
const Vavess = React.lazy(() => import("./pages/_VAVES/Vavess"));
const BPM = React.lazy(() => import("./pages/BPMCX/BPM"));
const NetDeveloper = React.lazy(() =>
  import("./pages/Careers/Jobs/NetDeveloper")
);
const BusinesDev = React.lazy(() => import("./pages/Careers/Jobs/BusinesDev"));
const BusinessHead = React.lazy(() =>
  import("./pages/Careers/Jobs/BusinessHead")
);
const LeadConsultant = React.lazy(() =>
  import("./pages/Careers/Jobs/LeadConsultant")
);
const SolutionConsultant = React.lazy(() =>
  import("./pages/Careers/Jobs/SolutionConsultant")
);
const Drupal = React.lazy(() => import("./pages/Careers/Jobs/Drupal"));
const Magento = React.lazy(() => import("./pages/Careers/Jobs/Magento"));
const Graphic = React.lazy(() => import("./pages/Careers/Jobs/Graphic"));
const Career_Job = React.lazy(() => import("./pages/Careers/Career_Job"));
const VivacisDpa = React.lazy(() => import("./pages/Vivacis/VivacisDpa"));
const Electronics_Company_Case_Study = React.lazy(() =>
  import("./pages/Customer/ElectronicsCompanyCaseStudy")
);
const Fabindia_Case_Study = React.lazy(() =>
  import("./pages/Customer/FabindiaCaseStudy")
);
const Giftcart_Case_Study = React.lazy(() =>
  import("./pages/Customer/GiftcartCaseStudy")
);
const Hdfc_Life_Insurance_Case_Study = React.lazy(() =>
  import("./pages/Customer/HdfcLifeInsuranceCaseStudy")
);
const Hike_Case_Study = React.lazy(() =>
  import("./pages/Customer/HikeCaseStudy")
);
const Jade_Global_Case_Study = React.lazy(() =>
  import("./pages/Customer/JadeGlobalCaseStudy")
);
const Kgisl_Case_Study = React.lazy(() =>
  import("./pages/Customer/KgislCaseStudy")
);
const Niit_Case_Study = React.lazy(() =>
  import("./pages/Customer/NiitCaseStudy")
);
const Securitas_Case_Study = React.lazy(() =>
  import("./pages/Customer/SecuritasCaseStudy")
);
const Announcing_Strategy = React.lazy(() =>
  import("./pages/News/AnnouncingStrategy")
);
const Coronavirus_Impact_On_Business = React.lazy(() =>
  import("./pages/News/CoronavirusImpactOnBusiness")
);
const Customer_Experience_Meet_2012 = React.lazy(() =>
  import("./pages/News/CustomerExperienceMeet2012")
);
const Gartner_Global_Marketing_Summit_2015 = React.lazy(() =>
  import("./pages/News/GartnerGlobalMarketingSummit2015")
);
const Giftcart_Launches_Its_Beta_Commerce_Site = React.lazy(() =>
  import("./pages/News/GiftcartLaunchesItsBetaCommerceSite")
);
const Maximizer_Virtuos_Announces_Strong_Partnership = React.lazy(() =>
  import("./pages/News/MaximizerVirtuosAnnouncesStrongPartnership")
);
const Oracle_Acquires_Rightnow_Technologies = React.lazy(() =>
  import("./pages/News/OracleAcquireRightnowTechnologies")
);
const Oracle_Welcomes_Virtuos = React.lazy(() =>
  import("./pages/News/OracleWelcomesVirtuos")
);
const Rightnow_Technologies_Honours_Virtuos = React.lazy(() =>
  import("./pages/News/Rightnow_Technologies_Honours_Virtuos")
);
const Unleashing_Business_4_Strategy = React.lazy(() =>
  import("./pages/News/Unleashing_Business_4_Strategy")
);
const Venky_Vijay_Speaks_To_The_Press = React.lazy(() =>
  import("./pages/News/Venky_Vijay_Speaks_To_The_Press")
);
const Verint_Acquires_Kana_Software = React.lazy(() =>
  import("./pages/News/Verint_Acquires_Kana_Software")
);
const Virtuos_Acquires_Enforce = React.lazy(() =>
  import("./pages/News/Virtuos_Acquires_Enforce")
);
const Virtuos_Announces_Post_Covid_Businesses = React.lazy(() =>
  import("./pages/News/Virtuos_Announces_Post_Covid_Businesses")
);
const Virtuos_Attends_Bpmonline = React.lazy(() =>
  import("./pages/News/Virtuos_Attends_Bpmonline")
);
const Virtuos_Attends_Dreamforce_2019 = React.lazy(() =>
  import("./pages/News/Virtuos_Attends_Dreamforce_2019")
);
const Virtuos_Attends_Dreamforce_2012 = React.lazy(() =>
  import("./pages/News/Virtuos_Attends_Dreamforce_2012")
);
const Virtuos_Attends_Gartner_2016 = React.lazy(() =>
  import("./pages/News/Virtuos_Attends_Gartner_2016")
);
const Virtuos_Attends_Kana_Customer_Summit_In_Uk = React.lazy(() =>
  import("./pages/News/Virtuos_Attends_Kana_Customer_Summit_In_Uk")
);
const Virtuos_Attends_Rightnow_User_Conference = React.lazy(() =>
  import("./pages/News/Virtuos_Attends_Rightnow_User_Conference")
);
const Virtuos_Awarded_As_Cx_Partner = React.lazy(() =>
  import("./pages/News/Virtuos_Awarded_As_Cx_Partner")
);
const Virtuos_Bags_Asia_Biggest_Ondemand_Crm = React.lazy(() =>
  import("./pages/News/Virtuos_Bags_Asia_Biggest_Ondemand_Crm")
);
const Virtuos_Bags_Crm_Contract_From_Leading_Bpo = React.lazy(() =>
  import("./pages/News/Virtuos_Bags_Crm_Contract_From_Leading_Bpo")
);
const Virtuos_Bags_Crm_Service_Cloud_Contract_From_Flipkart = React.lazy(() =>
  import("./pages/News/Virtuos_Bags_Crm_Service_Cloud_Contract_From_Flipkart")
);
const Virtuos_Bags_Orders_For_Kana_And_Maximizer = React.lazy(() =>
  import("./pages/News/Virtuos_Bags_Orders_For_Kana_And_Maximizer")
);
const Virtuos_Bags_Thomas_Cook_India = React.lazy(() =>
  import("./pages/News/Virtuos_Bags_Thomas_Cook_India")
);
const Virtuos_Becomes_Premium_Partner = React.lazy(() =>
  import("./pages/News/Virtuos_Becomes_Premium_Partner")
);
const Virtuos_Celebrates_Its_10th_Anniversary = React.lazy(() =>
  import("./pages/News/Virtuos_Celebrates_Its_10th_Anniversary")
);
const Virtuos_Celebrates_Its_11th_Anniversary = React.lazy(() =>
  import("./pages/News/Virtuos_Celebrates_Its_11th_Anniversary")
);
const Virtuos_Ceo_Attends_Dreamforce = React.lazy(() =>
  import("./pages/News/Virtuos_Ceo_Attends_Dreamforce")
);
const Virtuos_Clicksoftware_Sign_Partnership = React.lazy(() =>
  import("./pages/News/Virtuos_Clicksoftware_Sign_Partnership")
);
const Virtuos_Cloudle_Oracle = React.lazy(() =>
  import("./pages/News/Virtuos_Cloudle_Oracle")
);
const Virtuos_Co_Sponsors_Oracle = React.lazy(() =>
  import("./pages/News/Virtuos_Co_Sponsors_Oracle")
);
const Virtuos_Co_Sponsors_Oracle_2014 = React.lazy(() =>
  import("./pages/News/Virtuos_Co_Sponsors_Oracle_2014")
);
const Virtuos_Co_Sponsors_Oracle_Cloud_Mumbai = React.lazy(() =>
  import("./pages/News/Virtuos_Co_Sponsors_Oracle_Cloud_Mumbai")
);
const Virtuos_Co_Sponsors_Top_Indian_Banks_2008 = React.lazy(() =>
  import("./pages/News/Virtuos_Co_Sponsors_Top_Indian_Banks_2008")
);
const Virtuos_Completes_A_Wonderful_7_Years = React.lazy(() =>
  import("./pages/News/Virtuos_Completes_A_Wonderful_7_Years")
);
const Virtuos_Consultare_And_Oracle_Mou = React.lazy(() =>
  import("./pages/News/Virtuos_Consultare_And_Oracle_Mou")
);
const Virtuos_Consultare_Implements_China = React.lazy(() =>
  import("./pages/News/Virtuos_Consultare_Implements_China")
);
const Virtuos_Creates_New_Blueprint = React.lazy(() =>
  import("./pages/News/Virtuos_Creates_New_Blueprint")
);
const Virtuos_Embraces_Business_N_Model = React.lazy(() =>
  import("./pages/News/Virtuos_Embraces_Business_N_Model")
);
const Virtuos_Enveils_A_Major_Rebranding = React.lazy(() =>
  import("./pages/News/Virtuos_Enveils_A_Major_Rebranding")
);
const Virtuos_Exonomy_Invests_In_Experience = React.lazy(() =>
  import("./pages/News/Virtuos_Exonomy_Invests_In_Experience")
);
const Virtuos_Holds_Its_First_Experience = React.lazy(() =>
  import("./pages/News/Virtuos_Holds_Its_First_Experience")
);
const Virtuos_Implements_Clicksoftware = React.lazy(() =>
  import("./pages/News/Virtuos_Implements_Clicksoftware")
);
const Virtuos_Implements_Kana_Solution = React.lazy(() =>
  import("./pages/News/Virtuos_Implements_Kana_Solution")
);
const Virtuos_Implements_Oracle_Cx = React.lazy(() =>
  import("./pages/News/Virtuos_Implements_Oracle_Cx")
);
const Virtuos_Implements_Salesforce_At_Paytm = React.lazy(() =>
  import("./pages/News/Virtuos_Implements_Salesforce_At_Paytm")
);
const Virtuos_Incorporated_In_Hyderabad = React.lazy(() =>
  import("./pages/News/Virtuos_Incorporated_In_Hyderabad")
);
const Virtuos_India_Closes_Financial_Year_2012 = React.lazy(() =>
  import("./pages/News/Virtuos_India_Closes_Financial_Year_2012")
);
const Virtuos_Joins_Sap_Partneredge_Program = React.lazy(() =>
  import("./pages/News/Virtuos_Joins_Sap_Partneredge_Program")
);
const Virtuos_Launches_Co_Extend_Delivery_Methodology = React.lazy(() =>
  import("./pages/News/Virtuos_Launches_Co_Extend_Delivery_Methodology")
);
const Virtuos_Launches_Consultare_Division = React.lazy(() =>
  import("./pages/News/Virtuos_Launches_Consultare_Division")
);
const Virtuos_Launches_Innovation_Open = React.lazy(() =>
  import("./pages/News/Virtuos_Launches_Innovation_Open")
);
const Virtuos_Launches_New_Division_Yippee_Media = React.lazy(() =>
  import("./pages/News/Virtuos_Launches_New_Division_Yippee_Media")
);
const Virtuos_Launches_New_Edition_Of_Website = React.lazy(() =>
  import("./pages/News/Virtuos_Launches_New_Edition_Of_Website")
);
const Virtuos_Leadership_Meets_Maximizer_Team_In_Uk = React.lazy(() =>
  import("./pages/News/Virtuos_Leadership_Meets_Maximizer_Team_In_Uk")
);
const Virtuos_Moves_To_A_New_Office_2013 = React.lazy(() =>
  import("./pages/News/Virtuos_Moves_To_A_New_Office_2013")
);
const Virtuos_Moves_To_A_New_Office_In_Gurgaon = React.lazy(() =>
  import("./pages/News/Virtuos_Moves_To_A_New_Office_In_Gurgaon")
);
const Virtuos_Moves_To_New_Office_edg = React.lazy(() =>
  import("./pages/News/Virtuos_Moves_To_New_Office_edg")
);
const Virtuos_Opens_New_Office_In_Mumbai = React.lazy(() =>
  import("./pages/News/Virtuos_Opens_New_Office_In_Mumbai")
);
const Virtuos_Oracle_Cx_Tour_In_Delhi_Mumbai = React.lazy(() =>
  import("./pages/News/Virtuos_Oracle_Cx_Tour_In_Delhi_Mumbai")
);
const Virtuos_Participates_In_Dreamforce_2015 = React.lazy(() =>
  import("./pages/News/Virtuos_Participates_In_Dreamforce_2015")
);
const Virtuos_Presents_Business_Through_Social_At_Oracle = React.lazy(() =>
  import("./pages/News/Virtuos_Presents_Business_Through_Social_At_Oracle")
);
const Virtuos_Signs_Partnership_With_Rightnow = React.lazy(() =>
  import("./pages/News/Virtuos_Signs_Partnership_With_Rightnow")
);
const Virtuos_Signs_Two_New_Deals = React.lazy(() =>
  import("./pages/News/Virtuos_Signs_Two_New_Deals")
);
const Virtuos_Signs_Up_With_Gartner_India = React.lazy(() =>
  import("./pages/News/Virtuos_Signs_Up_With_Gartner_India")
);
const Virtuos_Signs_Up_With_Maximizer = React.lazy(() =>
  import("./pages/News/Virtuos_Signs_Up_With_Maximizer")
);
const Virtuos_Signs_With_Myntra = React.lazy(() =>
  import("./pages/News/Virtuos_Signs_With_Myntra")
);
const Virtuos_Snaps_Thomas_Cook_India = React.lazy(() =>
  import("./pages/News/Virtuos_Snaps_Thomas_Cook_India")
);
const Virtuos_Solutions_Changes = React.lazy(() =>
  import("./pages/News/Virtuos_Solutions_Changes")
);
const Virtuos_Solutions_Moves_To_A_New_Office = React.lazy(() =>
  import("./pages/News/Virtuos_Solutions_Moves_To_A_New_Office")
);
const Virtuos_Team_Attends_Cx_Io_Gartner_Marketing_Summit = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Cx_Io_Gartner_Marketing_Summit")
);
const Virtuos_Team_Attends_Dreamforce_2013 = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Dreamforce_2013")
);
const Virtuos_Team_Attends_Dreamforce_2017 = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Dreamforce_2017")
);
const Virtuos_Team_Attends_Gartner_2018 = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Gartner_2018")
);
const Virtuos_Team_Attends_Gartner_2019 = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Gartner_2019")
);
const Virtuos_Team_Attends_Oracle_2015 = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Oracle_2015")
);
const Virtuos_Team_Attends_Oracle_San_Francisco = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Oracle_San_Francisco")
);
const Virtuos_Team_Attends_Rightnow_Austraila = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Rightnow_Austraila")
);
const Virtuos_Team_Attends_Salesforce_Conference_2016 = React.lazy(() =>
  import("./pages/News/Virtuos_Team_Attends_Salesforce_Conference_2016")
);
const Virtuos_Teams_Undergo_Maximizer_Crm = React.lazy(() =>
  import("./pages/News/Virtuos_Teams_Undergo_Maximizer_Crm")
);
const Virtuos_To_Enter_In_Ecommerce = React.lazy(() =>
  import("./pages/News/Virtuos_To_Enter_In_Ecommerce")
);
const Virtuos_To_Focus_On_Global_Expansion = React.lazy(() =>
  import("./pages/News/Virtuos_To_Focus_On_Global_Expansion")
);
const Virtuos_To_Implement_Maximizer_Crm = React.lazy(() =>
  import("./pages/News/Virtuos_To_Implement_Maximizer_Crm")
);
const Virtuos_To_Unveil_Its_First_Product_Strategy_Aury = React.lazy(() =>
  import("./pages/News/Virtuos_To_Unveil_Its_First_Product_Strategy_Aury")
);
const Virtuos_To_Unveil_NOVV = React.lazy(() =>
  import("./pages/News/Virtuos_To_Unveil_NOVV")
);
const Virtuos_Veracis_Launches_Cxdesk_Expedience = React.lazy(() =>
  import("./pages/News/Virtuos_Veracis_Launches_Cxdesk_Expedience")
);
const Virtuos_Verint_Sign_Partnership = React.lazy(() =>
  import("./pages/News/Virtuos_Verint_Sign_Partnership")
);
const Virtuos_Vivacis_Global_Projects = React.lazy(() =>
  import("./pages/News/Virtuos_Vivacis_Global_Projects")
);
const Virtuos_Vivacis_Implements_CLM = React.lazy(() =>
  import("./pages/News/Virtuos_Vivacis_Implements_CLM")
);
const Virtuos_Vivacis_Oracle_Netsuite_Form_Alliance = React.lazy(() =>
  import("./pages/News/Virtuos_Vivacis_Oracle_Netsuite_Form_Alliance")
);
const Virtuos_Vlocis_Implements_CLM = React.lazy(() =>
  import("./pages/News/Virtuos_Vlocis_Implements_CLM")
);
const Contact_Us_For_Demo = React.lazy(() =>
  import("./pages/Agiloft/Forms/ContactUsForDemo")
);
const Seismicity = React.lazy(() => import("./pages/seismicity/Seismicity"));
const Salesforce = React.lazy(() => import("./pages/salesforce/Salesforce"));
const PageNotFound = React.lazy(() =>
  import("./pages/PageNotFound/PageNotFound")
);
const Sidebar = React.lazy(() => import("./pages/Sidebar/Sidebar"));
const Events = React.lazy(() => import("./pages/News/Events/Events"));
const Virtuos_Cx_Summit_2016 = React.lazy(() =>
  import("./pages/News/Events/Virtuos_Cx_Summit_2016")
);
// const Smartsheet = React.lazy(() => import("./components/Smartsheet/new"));
// const Philips = React.lazy(() => import("./components/Smartsheet/philips"));
// const Zoom = React.lazy(() => import("./components/Smartsheet/zoom"));
// const HP = React.lazy(() => import("./components/Smartsheet/hp"));
const Asana = React.lazy(() => import("./pages/Asana/Asana"));
const Virtuos_digital = React.lazy(() =>
  import("./pages/News/virtuos-digital")
);
const Virtuos_USA_Trip = React.lazy(() => import("./pages/News/USA-Trip-2023"));
const Virtuos_Smartsheet = React.lazy(() =>
  import("./pages/News/virtuos-smartsheet")
);
const Insidex = React.lazy(() => import("./pages/Insidex/Insidex"));
// const SmartsheetNew = React.lazy(() =>
//   import("./components/Smartsheet/smartsheet")

const ComingSoon = React.lazy(() => import("./pages/ComingSoon/ComingSoon"));
const Ai_ambition = React.lazy(() => import("./pages/Ai-ambition/Ai_ambition"));
const Crm_studio = React.lazy(() => import("./pages/Crm-studio/Crm_studio"));
const Wundero = React.lazy(() => import("./pages/Wundero/Wundero"));
const Hyper_Automation = React.lazy(() =>
  import("./pages/Hyper-Automation/Hyper_Automation")
);
const Big_think = React.lazy(() => import("./pages/Big_think/Big_Think"));
const Blog = React.lazy(() => import("./pages/Blogs/VirtuosBlog"));
const BlogDetail = React.lazy(() => import("./pages/BlogDetail/BlogDetail"));
const Page = React.lazy(() => import("./components/SEO/page"));
// import Blog1 from "./pages/blog/building-an-effective-crm-platform-with-low-code-tool-for-business";
const Main = React.lazy(() => import("./components/Main"));
const Org = React.lazy(() => import("./pages/Org/Org"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Consultare = React.lazy(() => import("./pages/Consultare/Consultare"));
const Exonomy = React.lazy(() => import("./pages/Xonomy/Exonomy"));
const Xonomy = React.lazy(() => import("./pages/Xonomy/Xonomy"));

const Buisness = React.lazy(() => import("./pages/Buisness"));
const Corporate = React.lazy(() => import("./pages/Corporate/Corporate"));
const Crm_Digital = React.lazy(() => import("./pages/CRM-Digital/CrmDigital"));
const CX = React.lazy(() => import("./pages/CX/CX"));
const EX = React.lazy(() => import("./pages/EX/EX"));
const XX_Digital = React.lazy(() => import("./pages/XX_Digital/XX_Digital"));
const Alliances = React.lazy(() => import("./pages/Alliance/Alliances"));
const Crm_Digital_Sales = React.lazy(() =>
  import("./pages/CRM-Digital/CrmDigitalSales")
);
const Crm_Digital_Service = React.lazy(() =>
  import("./pages/CRM-Digital/CrmDigitalService")
);
const Crm_Digital_Marketing = React.lazy(() =>
  import("./pages/CRM-Digital/CrmDigitalMarketing")
);
const Crm_Digital_Commerce = React.lazy(() =>
  import("./pages/CRM-Digital/CrmDigitalCommerce")
);
const Consultare_Professional_Service = React.lazy(() =>
  import("./pages/Consultare/ConsultareProfessionalService")
);
const C_Digital = React.lazy(() => import("./pages/C_Digital/CDigital"));
const CX_Strategy_Design = React.lazy(() =>
  import("./pages/CX/CXStrategyDesign")
);
const Rainbow = React.lazy(() => import("./pages/Rainbowcx/Rainbow"));
const CXNow = React.lazy(() => import("./pages/CX_NOW/CXNow"));
const CXDesk = React.lazy(() => import("./pages/CX_Desk/CXDesk"));
const Vaves = React.lazy(() => import("./pages/Vaves"));
const CxNow_CXprimer = React.lazy(() => import("./pages/CX_NOW/CxNowCXprimer"));
const EX_StrategyDesign = React.lazy(() =>
  import("./pages/EX/EXStrategyDesign")
);

const W_digital = React.lazy(() => import("./pages/W_Digital/W_digital"));
const Wdigital_program = React.lazy(() =>
  import("./pages/W_Digital/Wdigital_program")
);
const Talentare = React.lazy(() => import("./pages/Talentare/Talentare"));
const IT = React.lazy(() => import("./pages/IT/IT"));
const Veracis = React.lazy(() => import("./pages/Veracis/Veracis"));
const Vivacis = React.lazy(() => import("./pages/Vivacis/Vivacis"));
const Consultare_engineering_services = React.lazy(() =>
  import("./pages/Consultare/Consultareengineeringservices")
);
const Cloud = React.lazy(() => import("./pages/Cloud/Cloud"));
const Right_apps = React.lazy(() => import("./pages/Right_apps/Rightapps"));
const Oracle = React.lazy(() => import("./pages/Oracle/Oracle"));
const Microsoft = React.lazy(() => import("./pages/Microsoft"));
const Creatio = React.lazy(() => import("./pages/Creatio/Creatio"));
const Agiloft = React.lazy(() => import("./pages/Agiloft/Agiloft"));
const Alliances_specialized = React.lazy(() =>
  import("./pages/Alliance/AlliancesSpecialized")
);
const Io = React.lazy(() => import("./pages/IO/Io"));
const Ventures = React.lazy(() => import("./pages/Ventures/Ventures"));
const Nexorama_wundero = React.lazy(() =>
  import("./pages/Nexorama/Nexoramawundero")
);
const Nexorama_expedience = React.lazy(() =>
  import("./pages/Nexorama/Nexoramaexpedience")
);
const Solutions = React.lazy(() => import("./pages/Solution/Solutions"));
const Industry = React.lazy(() => import("./pages/Industry/Industry"));
const Business4_0 = React.lazy(() => import("./pages/Buisness4-0/Business4_0"));
const Ex_digital = React.lazy(() => import("./pages/EX_Digital/Exdigital"));
const Solutions_sales = React.lazy(() =>
  import("./pages/Solution/Solutionssales")
);
const Solutions_marketing = React.lazy(() =>
  import("./pages/Solution/Solutionsmarketing")
);
const Solutions_service = React.lazy(() =>
  import("./pages/Solution/Solutionsservice")
);
const Solutions_commerce = React.lazy(() =>
  import("./pages/Solution/Solutionscommerce")
);
const O_digital = React.lazy(() => import("./pages/O_Digital/Odigital"));
const VivacisOracleNetsuite = React.lazy(() =>
  import("./pages/Vivacis/VivacisOracleNetsuite")
);
const Customers = React.lazy(() => import("./pages/Customer/Customers"));
const Careers = React.lazy(() => import("./pages/Careers/Careers"));
const News = React.lazy(() => import("./pages/News/News"));
const Corporate_Story = React.lazy(() =>
  import("./pages/Corporate/CorporateStory")
);

// import Crayonz from './pages/Crayonz/Crayonz';
const Corporate_Team = React.lazy(() =>
  import("./pages/Corporate/CorporateTeam")
);
const Virtuosoship = React.lazy(() => import("./pages/Virtuosoship"));
const Investors = React.lazy(() => import("./pages/Investors"));
const Community = React.lazy(() => import("./pages/Community/Community"));
const Corporate_Experience_Edge = React.lazy(() =>
  import("./pages/Corporate/CorporateExperienceEdge")
);
const Succeedant = React.lazy(() => import("./pages/Succeedant"));
const Insights = React.lazy(() => import("./pages/Insights/Insights"));
const Customers_Success_Stories = React.lazy(() =>
  import("./pages/CustomersSuccessStories")
);
const Careers_Opportunities = React.lazy(() =>
  import("./pages/Careers/Careers_Opportunities")
);
const Webinars = React.lazy(() => import("./pages/Webinars/Webinars"));
const Branding = React.lazy(() => import("./pages/Branding/Branding"));
const Consultare_technology_services = React.lazy(() =>
  import("./pages/Consultare/Consultaretechnologyservices")
);
const Business3 = React.lazy(() => import("./pages/Business3"));
const Indicial = React.lazy(() => import("./pages/Indicial"));
const Consultare_Applications_Group = React.lazy(() =>
  import("./pages/Consultare/ConsultareApplicationsGroup")
);
const Consultare_Crm_Cx = React.lazy(() =>
  import("./pages/Consultare/ConsultareCrmCx")
);
const Consultare_Pace_Layered = React.lazy(() =>
  import("./pages/Consultare/ConsultarePaceLayered")
);
const Consultare_Platform_Ecosystem = React.lazy(() =>
  import("./pages/Consultare/ConsultarePlatformEcosystem")
);
const Consultare_Experience_Design = React.lazy(() =>
  import("./pages/Consultare/ConsultareExperienceDesign")
);
const Flipkart_Case_Study = React.lazy(() =>
  import("./pages/Customer/FlipkartCaseStudy")
);
const Standard_Chartered_Case_Study = React.lazy(() =>
  import("./pages/Customer/StandardCharteredCaseStudy")
);
const Makemytrip_Case_Study = React.lazy(() =>
  import("./pages/Customer/MakemytripCaseStudy")
);

// Custom scroll-to-top component

const Indiafirst_Case_Study = React.lazy(() =>
  import("./pages/Customer/IndiafirstCaseStudy")
);
const Creatio_Crm_For_Power_CO_Case_Study = React.lazy(() =>
  import("./pages/Customer/CreatioCrmForPowerCOCaseStudy")
);
const Agiloft_Clm_For_Internet = React.lazy(() =>
  import("./pages/Customer/AgiloftClmForInternet")
);
const Tvs_Credit_Case_Study = React.lazy(() =>
  import("./pages/Customer/TvsCreditCaseStudy")
);
const ThomasCookInidia = React.lazy(() =>
  import("./pages/Customer/ThomasCookInidia")
);
const Entertainment_compnay = React.lazy(() =>
  import("./pages/Customer/Entertainmentcompnay")
);
const Myntra = React.lazy(() => import("./pages/Customer/Myntra"));
const Career_Expereince_Job = React.lazy(() =>
  import("./pages/Careers/Career_Expereince_Job")
);
const Career_Culture = React.lazy(() =>
  import("./pages/Careers/Career_Culture")
);
const Life_at_virtuos = React.lazy(() =>
  import("./pages/Careers/Life_at_virtuos")
);
const Training_Developments = React.lazy(() =>
  import("./pages/Careers/Training_Developments")
);
const Happiest_culture = React.lazy(() =>
  import("./pages/Careers/Happiest_culture")
);
const Employee_Benefit = React.lazy(() =>
  import("./pages/Careers/Employee_Benefit")
);
const Outlook = React.lazy(() => import("./pages/Careers/Outlook"));
const Virtuos_Alumni = React.lazy(() =>
  import("./pages/Careers/Virtuos_Alumni")
);
const Agilenterprise = React.lazy(() =>
  import("./pages/Buisness4-0/Agilenterprise")
);
const All_Agile = React.lazy(() => import("./pages/All-Agile/AllAgile"));
const Invincible = React.lazy(() => import("./pages/Invincible"));
const Vuca = React.lazy(() => import("./pages/Vuca/Vuca"));
const Vuca_Covid = React.lazy(() => import("./pages/Vuca/VucaCovid"));
const Unified_Desk = React.lazy(() => import("./pages/CX_Desk/UnifiedDesk"));
const Customer_Success = React.lazy(() =>
  import("./pages/CX_Desk/CustomerSuccess")
);
const Cxopia = React.lazy(() => import("./pages/CX_NOW/Cxopia"));
const Pyramid = React.lazy(() => import("./pages/CX_NOW/Pyramid"));

// const routes = [
//   {
//     path: "/",
//     // loader() {
//     //   return json({ message: "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd." });
//     // },
//     element: (
//       <Page
//         title: "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd."
//         description: "Virtuos is a leading Digital Transformation Company providing strategy, consulting, and technology engineering services for CRM, Customer Experience (CX), Employee Experience (EX), and Everything Experience to develop Low-Code No-Code Technologies. To Consultant, Contact us today!"
//         keywords: "Post-COVID Agile strategy, customer experience, CX, Employee Experience, EX, oracle CX, salesforce CRM, microsoft dynamics, Powerapps, digital strategy consulting, AI chatbot, CX.Digital Transformation, Rainbow CX, Crayonz HRTech, cxdesk operations, contract lifecycle management, CLM, customer voice, customer journey, cx audit, cx metrics, Netsuite ERP, Vedam, Digital Marketing, Indicial, Digital Maturity"
//       >
//         <Main />
//       </Page>
//     ),
//   },
//   {
//     path: "/org",
//     loader() {
//       return json({ message: "Welcome to React Router!" });
//     },1
//     element: (
//       <Page
//         title: "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd."
//         description: "Virtuos is a leading Digital Transformation Company providing strategy, consulting, and technology engineering services for CRM, Customer Experience (CX), Employee Experience (EX), and Everything Experience to develop Low-Code No-Code Technologies. To Consultant, Contact us today!"
//         keywords: "Post-COVID Agile strategy, customer experience, CX, Employee Experience, EX, oracle CX, salesforce CRM, microsoft dynamics, Powerapps, digital strategy consulting, AI chatbot, CX.Digital Transformation, Rainbow CX, Crayonz HRTech, cxdesk operations, contract lifecycle management, CLM, customer voice, customer journey, cx audit, cx metrics, Netsuite ERP, Vedam, Digital Marketing, Indicial, Digital Maturity"
//       >
//         <Org />
//       </Page>
//     ),
//   },
//   {
//     path: "/agnitor",
//     loader() {
//       return json({ message: "Welcome to React Router!" });
//     },
//     // component: function WelcomeComponent() {
//     //     console.log("hello", useLoaderData());
//     //     let data = useLoaderData();
//     //     return <h1>{data.message}</h1>
//     // }
//     element: <Agnitor />,
//   },
// ];

const routes = [
  {
    path: "/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      description:
        "Virtuos is a leading Digital Transformation Company providing strategy, consulting, and technology engineering services for CRM, Customer Experience (CX), Employee Experience (EX), and Everything Experience to develop Low-Code No-Code Technologies. To Consultant, Contact us today!",
      keywords:
        "Post-COVID Agile strategy, customer experience, CX, Employee Experience, EX, oracle CX, salesforce CRM, microsoft dynamics, Powerapps, digital strategy consulting, AI chatbot, CX.Digital Transformation, Rainbow CX, Crayonz HRTech, cxdesk operations, contract lifecycle management, CLM, customer voice, customer journey, cx audit, cx metrics, Netsuite ERP, Vedam, Digital Marketing, Indicial, Digital Maturity",
    },
    element: <Main />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/index",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      description:
        "Virtuos is a leading Digital Transformation Company providing strategy, consulting, and technology engineering services for CRM, Customer Experience (CX), Employee Experience (EX), and Everything Experience to develop Low-Code No-Code Technologies. To Consultant, Contact us today!",
      keywords:
        "Post-COVID Agile strategy, customer experience, CX, Employee Experience, EX, oracle CX, salesforce CRM, microsoft dynamics, Powerapps, digital strategy consulting, AI chatbot, CX.Digital Transformation, Rainbow CX, Crayonz HRTech, cxdesk operations, contract lifecycle management, CLM, customer voice, customer journey, cx audit, cx metrics, Netsuite ERP, Vedam, Digital Marketing, Indicial, Digital Maturity",
    },
    element: <Main />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/org",
    handle: {
      title: "Creating shared value and building society 5.0 – Virtuos.org",
      description:
        "Establish meaningful lives for the underprivileged society by working with communities creating shared value and by helping through the aid of Philanthropic 1-2-3 Edge model.",
      keywords: "",
    },
    element: <Org />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/agnitor",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Agnitor, The Expertise Crowd – Virtuos Xonomy",
      description:
        "Agnitor means a talented Person who recognizes, understands and perceives the opportunities. Agintor, the expertise crowd",
      keywords: "",
    },
    element: <Agnitor />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/qualtrics",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Digital | Qualtrics XM Services",
      description:
        "Virtuos Qualtrics provides a unique blend of digital design talents and cutting-edge XM Technologies used by 85% of Fortune 100 companies",
      keywords: "",
    },
    element: <Qualtrics />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/contact",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Contact us at Virtuos | Global Offices and Contacts – Virtuos Digital Ltd.",
      description:
        "Virtuos has global offices and contacting Virtuos is easy. Virtuos Virtual Offices available at multiple locations. Contact us in SAUCE countries - South Africa, Australia, US, Canada, and EMEA regions. Press and Media contacts",
      keywords:
        "Virtuos contacts, Contact information, VVO, Virtuos Virtual Offices, SAUCE countries, South Africa, Australia, USA, Canada, EMEA, global offices, technical support, careers",
    },
    element: <Contact />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM & CX Digital Transformation Consulting Industries Services | Virtuos Digital Ltd.",
      description:
        "Virtuos Consultare is High Performance CX Consulting firm on customer digital strategy. Operational Efficiency and Digital workplace transformation",
      keywords:
        'Consultare means experts to consult, Customer Digital Strategy, Operational Efficiency, Workplace Transformation, customer is at heart, Digital Darwinists, compelling vision of " big change ", CXDESK, Digital ASAP, Talentare, Customer Trust, Sales, Service, Marketing, Commerce, CRM, Vivacis, CRM Digital, Customer success, employee experience, Digital disruption, Voice of the Customer, Digital Transformation, across Industries, Retail, ECommerce, Travel, Hospitality, Banking & Financial, CX Pyramid, the State of Customer Experience (CX), CX Diagnostics, CLM, Cloud Infrastructure, Digital Automation, Mobile App development, Engineering services, Professional Services',
    },
    element: <Consultare />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/exonomy",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Experience Economy | Innovation and Incubation arm – Xonomy Virtuos Digital Ltd.",
      description:
        "Virtuos Xonomy — The Innovation and Incubation arm foraying into digital products in the experience economy.",
      keywords:
        "incubation, innovation, innovation on, New role of CEO, demystifying ExperienCEO, Business 4.0, paradigm shift, Experience Cloud, Xonomy platform, Weprospect sales attunement, Budh xonomy, Business model, winning as a gamechanger, aury, chatbot, budh, cloudle, vyo, brandcart, giftcart, indic, rigour, vedam, weprospect, enforce, powervoice",
    },
    element: <Exonomy />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/exonomy/budh",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Budh Nextgen Sustainable Business | Experience Economy – Virtuos Xonomy",
      description:
        "At Budh Xonomy, we envision a new world of tomorrow. An exciting opportunity to create new ecosystems – more resource efficient and sustainable",
      keywords: "",
    },
    element: <Exonomy_Budh />,
    errorElement: <PageNotFound />,
  },
  {
    path: "exonomy/ai",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Artificial Intelligence | Ideas and Incubation – Virtuos Xonomy",
      description:
        "Artificial Intelligence stands out as the next biggest transformation in the digital age even surpassing the impact we have witnessed with Internet.",
      keywords: "",
    },
    element: <Exonomy_Ai />,
    errorElement: <PageNotFound />,
  },
  {
    path: "exonomy/ai/self-service",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Rainbow CX AI Driven Customer Self-Service – Virtuos Xonomy",
      description:
        "AI powered self-service solutions enable your customers to proactively find the information they're looking for without needing to contact your customer support agents.",
      keywords: "",
    },
    element: <Exonomy_Ai_Selservice />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/xonomy",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Experience Economy | Innovation and Incubation arm – Xonomy Virtuos Digital Ltd.",
      description:
        "Virtuos Xonomy — The Innovation and Incubation arm foraying into digital products in the experience economy.",
      keywords:
        "incubation, innovation, innovation on, New role of CEO, demystifying ExperienCEO, Business 4.0, paradigm shift, Experience Cloud, Xonomy platform, Weprospect sales attunement, Budh xonomy, Business model, winning as a gamechanger, aury, chatbot, budh, cloudle, vyo, brandcart, giftcart, indic, rigour, vedam, weprospect, enforce, powervoice",
    },
    element: <Xonomy />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/xonomy/budh",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Budh Nextgen Sustainable Business | Experience Economy – Virtuos Xonomy",
      description:
        "At Budh Xonomy, we envision a new world of tomorrow. An exciting opportunity to create new ecosystems – more resource efficient and sustainable",
      keywords: "",
    },
    element: <Xonomy_Budh />,
    errorElement: <PageNotFound />,
  },
  {
    path: "xonomy/ai",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Artificial Intelligence | Ideas and Incubation – Virtuos Xonomy",
      description:
        "Artificial Intelligence stands out as the next biggest transformation in the digital age even surpassing the impact we have witnessed with Internet.",
      keywords: "",
    },
    element: <Xonomy_Ai />,
    errorElement: <PageNotFound />,
  },
  {
    path: "xonomy/ai/self-service",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Rainbow CX AI Driven Customer Self-Service – Virtuos Xonomy",
      description:
        "AI powered self-service solutions enable your customers to proactively find the information they're looking for without needing to contact your customer support agents.",
      keywords: "",
    },
    element: <Xonomy_Ai_Selservice />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/business",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Becoming an invincible company | Our Business – Virtuos Digital Ltd.",
      description:
        "Virtuos, the Invincible Company explores the future, while excelling at exploiting the present",
      keywords: "",
    },
    element: <Buisness />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/corporate",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "About us | Corporate information | Digital Century Business – Virtuos Digital Ltd.",
      description:
        "Digital Century Business – 21st Century: where humans protect nature and ecoculture. A business that is digital and born in the 21st century to last forever undergoing iterative corrections continuously.",
      keywords:
        "21st Century, digital century business, UNDP’s sustainable developmental goals (SDG), democratization of customer experience, marketing, sales, the explicit mission to change the world, big change, big objectives, Business 4.0, 4.0 As A Strategy, cloud native, digital immigrant, Rid the world of bad experiences, Virtuoso talent, employee experience, One Vow, digitally dextrous employees, Consultare Business, Xonomy business, CRM and Customer Experience, experienceo, a different kind of company",
    },
    element: <Corporate />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/crm-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM Digital | Best CRM Software Implementation Virtuos Digital Ltd.",
      description:
        "Customer Relationship Management (CRM) is a customer management software that helps organizations manage their sales, marketing, and customer service efforts. To Consult or for more information, call us at 0124 498 5500.",
      keywords:
        "Marketing Analytics, Customer Data Platforms, Artificial Intelligence (AI), Account-Based Marketing, Cross-Device Identification, Multichannel Marketing Hubs, Journey Analytics, Personalization Engines, Sales Predictive Analytics, Price Optimization & Management, Configure, Price, Quote, Sales Analytics, Lead Management, Partner Relationship Management, Cognitive Expert Advisors, AI/Chatbots/VCA, Robotic Process Automation, API-Oriented Architecture, Personalization Mobile Sales Productivity",
    },
    element: <Crm_Digital />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cx",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Experience (CX) Consulting Services – Virtuos Digital Ltd.",
      description:
        "Customer Experience (CX) Strategy is a consulting plan that organizations make to ensure high-quality CX Experience and customer experience management. CX Transformation, CX Design & CX Technology. Hurry up! call us at 0124 498 5500.",
      keywords:
        "drivers of CX improvement, CX Strategy, head of cx, understand customers, develop cx strategy, design the CX, build customer centric culture, manage the CX, CX initiatives, CIOs, CFOs, customer experience vision, customer experience strategy, cx consulting, business outcomes",
    },
    element: <CX />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/ex",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Employee Experience (EX) Framework and Strategy | Virtuos Digital Ltd.",
      description:
        "Many leading organizations aim to improve the employee experience (EX) framework and strategy as a whole new practice offering. To know more, call us at 0124 498 5500.",
      keywords:
        "employee experience, EX, Post-COVID economy, EX has a new equation CX, employees with ownership quotient, EX strategy, Technology, Transformation, Continuous Next, Passion is an emotion, Passion is Selling yourself, Definition of Employee Experience (EX), Culture Influences the Experience, Outcome of the Experience, employment value proposition (EVP), Talent Outcomes, Business Outcomes, Customer Satisfaction, innovation, Organizational Reputation, Discretionary Effort, employee performance, Investing in CX, Voice of the Employee, Employee sentiment analytics, Recognition and rewards software, Social analytics tools, Pulse surveys, EX As A practice",
    },
    element: <EX />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/branding",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Branding Guidelines and Media Kit – Virtuos Digital",
      description:
        "The Virtuos logo is one of our most valuable corporate assets. It’s among the most recognized corporate identities in the world, uniquely distinguishing us from our competitors and other companies",
      keywords: "",
    },
    element: <Branding />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/xx-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "XX and Digital Integrated Transformation | Virtuos Digital Ltd.",
      description:
        "Highly Trusted Enterprise IT Solutions. As a Digitally Native Company Born in the Cloud, Virtuos Transforms Companies With Cloud First to Cloud Only Strategies. To Know More Information, Contact Us at 0124 498 5500.",
      keywords:
        "enterprise IT, IT Solutions, digitally native company, born in the cloud, cloud first to cloud only, cloud strategies, mobile applications and development, rightapps, Progressive Web Apps (PWA), Android, IOS Platform based applications, virtuos experience design, Digital Design Thinking foundation, empathy for mindfulness, Virtuos Vivacis, Contract Lifcycle Management (CLM), CPQ, RPA, Virtuos Cloud, CloudMastermind Strategy, O.digital mastermind",
    },
    element: <XX_Digital />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/alliances",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Alliances and Partnerships | Business & Technology partners – Virtuos Digital Ltd.",
      description:
        "Virtuos Consultare has forged alliances with leaders in CRM and Customer Experience Technologies across many geographies to serve customers with our high performance consulting — Experience Design, Implementation and Transformation services. To consultant call us today at 0124 498 5500.",
      keywords:
        "Virtuos and Oracle, Virtuos and Salesforce, Virtuos and Microsoft, BPMOnline, Creatio, Salesforce, Oracle Service Cloud, Oracle marketing cloud, Oracle engagement cloud, Oracle, salesforce sales cloud, salesforce service cloud, salesforce fieldservice lightning, Dynamics Field service, Dynamics Sales, Dynamics Customer Service, Microsoft, Verint, Kana, Agiloft, DOMO, Open methods, Partnerships, Alliances, strategic partners, technology partners, crm partners, crm alliances, crm partnership",
    },
    element: <Alliances />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/crm-digital/sales",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM Sales force Automation Consulting Services – Virtuos CRM Digital",
      description:
        "State of the Art Sales force Automation (SFA) Consulting Services. Virtuos delivers industry's leading edge best practices for sales professionals.",
      keywords: "",
    },
    element: <Crm_Digital_Sales />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/crm-digital/service",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM Customer Service and Customer Engagement Center Consulting – Virtuos CRM Digital",
      description:
        "The Three Customer Service Megatrends in 2020: Fuse AI and Agents to Drive Better Experiences",
      keywords: "",
    },
    element: <Crm_Digital_Service />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/crm-digital/marketing",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM Digital Marketing and Lead Management Consulting – Virtuos CRM Digital",
      description:
        "Here are the Most Vital Capabilities That Support Marketing Strategy Delivery Over the Next 18 Months — after the COVID impact.",
      keywords: "",
    },
    element: <Crm_Digital_Marketing />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/crm-digital/commerce",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "CRM Digital Commerce Consulting Services– Virtuos CRM Digital",
      description:
        "The future of Ecommerce is X Commerce. Look beyond commoditized commerce functionality and focus on delivering superior customer experiences.",
      keywords: "",
    },
    element: <Crm_Digital_Commerce />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/professional-services",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM Professional Services | Implementation and Customization – Virtuos Consultare",
      description:
        "Virtuos Consultare delivers innovative solutions for better business insight, adaptive business processes, and superior ownership experience.",
      keywords: "",
    },
    element: <Consultare_Professional_Service />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/c-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Digital Strategy Consulting by C.Digital Masterminds – Virtuos Digital Ltd.",
      description:
        "Cutting Edge Customer Relationship and Customer Experience. We deliver high performance and innovative Professional Services to design, build, and transform your CRM and CX Systems.",
      keywords:
        "Customer Digital Strategy Consulting, Customer Relationship, Customer Experience, CRM, CX Systems, professional services, Complex Customer Experience (CX) Design, CRM Technology Implementation, Marketing, Commerce, Analytics, Omnichannel customer interactions, 100+ CRM Technologies, CRM Functionalities, CustomerJourneys, cxdesk, crm sales, crm service, crm marketing, crm commerce, Design with love, Pace Layered Approach, DXP Ready Build, Thomas Cook, India delivering world-class CX, industry 4.0 designed, cx strategy",
    },
    element: <C_Digital />,
    errorElement: <PageNotFound />,
  },

  {
    path: "blog",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Blog",
      description: "",
      keywords: "",
    },
    element: <Blog />,
    errorElement: <PageNotFound />,
  },

  {
    path: "blog/5-factors-you-must-know-about-customer-service-experience",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "5 Factors You Must Know about Customer Service Experience",
      description:
        "Do you know how to enhance customer expectations? Read more on the VIRTUOS blog so you can take your customer experience (cx) up a notch. For more information, Contact us at +91 124-498-5500.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/salesforce-automation-tools-a-quick-guide-in-4-points",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Salesforce Automation Tools: A Quick Guide In 4 Points",
      description:
        "Salesforce is the number one CRM system available in the market today. It has built its reputation over the years, building on its ability to completely. To know more information, call us at +91 124-498-5500.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/contract-lifestyle-management-what-you-should-know-about-it",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Contract Lifestyle Management: What You Should Know About It",
      description:
        "Searching for CLM systems or software to give structure to the contract lifecycle? VIRTUOS tells how to involves tracking and manage different aspects of a contract throughout its lifecycle and ensures successful contract management. To Consultant call now!",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/customer-relationship-management-crm-advantages-of-hiring-crm-consultancy-services",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Relationship Management (CRM): Advantages of Hiring CRM Consultancy Services",
      description:
        "Virtuos, the best CRM consultancy, offers tailored solutions to bridge the gaps in your processes and boost your customer retention rate. Maximize the performance of your CRM platform with their guidance. To consultant call us today at +91 124-498-5500.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/what-makes-digital-transformation-relevant-for-business-organizations",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "What Makes Digital Transformation Relevant For Business Organizations",
      description:
        "Discover the power of digital transformation for your business. Enhance security, increase productivity, and improve customer satisfaction by leveraging innovative technologies. Partner with Virtuos, the reliable choice for successful digital transformation.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/benefits-of-using-creatio-crm-low-code-no-code-platform",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Benefits of Using Creatio CRM's Low Code No Code Platform",
      description:
        "Discover how a Low Code No Code Platform like Creatio CRM can help you streamline your business processes on Virtuos.com.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/how-to-prepare-your-team-to-fulfill-future-vision-at-work",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "How to Prepare Your Team to Fulfill Future Vision at Work?",
      description:
        "In regard to the future of work, business leaders need to be thoughtful about the employee experience. The concept of working from anywhere, from any device, needs to figure out how to keep the work level productive.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/how-organization-benefits-from-talent-strategies-for-experience-job",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "How Organization Benefits from Talent Strategies for Experience Job?",
      description:
        "Enhancing an organization's customer experience is the goal of experience jobs. Implementing the most recent talent strategies can produce the best outcomes.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/how-rpa-is-a-process-of-fast-automation-to-handle-business-tasks",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "How RPA is a Process of Fast Automation to Handle Business Tasks?",
      description:
        "RPA (Robotic Process Automation) helps software users create software robots or bots that can replace human tasks precisely to a great extent.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/building-an-effective-crm-platform-with-low-code-tool-for-business",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Building an Effective CRM Platform with Low-Code Tool for Business",
      description:
        "Business processes can be accelerated like never before with a comprehensive CRM software and the top low code no code platform.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/how-to-connect-and-integrate-cx-and-ex-leads-to-more-ee",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "How to Connect and Integrate CX and EX Leads to More EE",
      description:
        "How to Aligning CX with EX Fuels Business Growth? Virtuos comprises employee experience (EX) and customer experience, working together to provide everything experience (EE). To Know more, contact us at our website.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/how-to-align-employee-experience-ex-and-customer-experience-cx",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "How To Align Employee Experience and Customer Experience",
      description:
        "CX reflects employee experience because all CX strategies impact employees directly. Business leaders should value their teams as much as customers and take employee insights into account.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/why-does-a-small-business-need-to-use-a-crm-platform",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Why does a Small Business Need To Use a CRM Platform?",
      description:
        "Using a CRM platform is now one of the quickest ways to grow your business. While customer relationship management (CRM) software benefits businesses of all sizes, it is especially beneficial to small businesses.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/why-companies-should-invest-in-better-cx",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Why Companies Should Invest In Better CX? - Virtuos Blogs",
      description:
        "With high-quality CX consulting services, it is now possible for a company to grow its customer base. For any company in the world, growing customers is usually a priority because that means more profits.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/5-pillars-of-great-cx",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "5 Pillars Of Great CX - Virtuos Blogs",
      description:
        "The customer experience CX practice can be a complicated one if a particular company does not know what it is doing. Let's know the 5 Pillars.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/what-does-modern-high-quality-customer-experience-look-like",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "What Does Modern High-Quality Customer Experience Look Like?",
      description:
        "The technologies are limitless in their potential, but when it is the CX strategy that is being talked about, it becomes a job that is best left for the professionals.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/how-do-sales-and-customer-service-automation-help-business-growth",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "How Do Sales And Customer Service Automation Help Business Growth?",
      description:
        "Many businesses have implemented customer service automation to increase the effectiveness of their customer support staff and Business ROI.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "blog/what-makes-digital-process-automation-a-new-trend-in-the-corporate-sector",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "What Makes Digital Process Automation a New Trend?",
      description:
        "Digital process automation (DPA) uses low-code development tools to automate processes that can span multiple applications.",
      keywords: "",
    },
    element: <BlogDetail />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/c-digital/customer360/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Customer 360 Program by C.Digital Masterminds – Virtuos Digital",
      description:
        "Do You Have a Customer 360 Program in Your Company? You need a customer engagement hub.",
      keywords: "",
    },
    element: <Customerthree />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/c-digital/customer360/ci/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer 360 Continuous Intelligence by C.Digital Mastermind – Virtuos Digital",
      description:
        "Customer 360 Continuous Intelligence: Customer moments are becoming smaller and they demand hyperpersonalization.",
      keywords: "",
    },
    element: <Customerthree_CI />,
    errorElement: <PageNotFound />,
  },
  {
    path: "c-digital/customer360/ceh/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Real-time Customer Engagement Hub. The C-Digital Mastermind – Virtuos Digital",
      description:
        "Making a business case for Customer Engagement Hub (CEH). C.Digital Mastermind Consulting for CEH",
      keywords: "",
    },
    element: <Customer_Engagement />,
    errorElement: <PageNotFound />,
  },
  {
    path: "c-digital/xlv/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "C.Digital Mastermind for CX – Customer ID and Experience ID – Virtuos Digital",
      description:
        "Building on the new Customer Engagement Value by building the best practices for Customer Lifetime Value (CLV) and introducing Experience Lifetime Value (XLV).",
      keywords: "",
    },
    element: <Expereince_ID />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/c-digital/customer-wide/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "C.Digital Where C means Customer – Virtuos Digital",
      description:
        "4 faces of Digital Consumer. As consumers become more digital, four attitudes are changing the way they expect to interact with organizations.",
      keywords: "",
    },
    element: <Customer_Wide />,
    errorElement: <PageNotFound />,
  },
  {
    path: "c-digital/digital-dragons/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Winning In A World of Digital Dragons. The C.Digital Mastermind – Virtuos Digital",
      description:
        "Every industry is becoming a digital industry. Products are becoming platforms, Digital services are differentiators. CX becomes digital anthropology",
      keywords: "",
    },
    element: <Digital_Dragon />,
    errorElement: <PageNotFound />,
  },
  {
    path: "digital/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Digital Transformation Services | Digital Strategies | CX.Digital  – Virtuos Digital",
      description:
        "Transform your CX.Digital vision with customer and market insights. Here are the three phases of roadmap application leaders focused on CX and Digital should support customer-centric strategies.",
      keywords: "",
    },
    element: <Digital />,
    errorElement: <PageNotFound />,
  },
  {
    path: "digital/digital-asap",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Accelerate with Digital ASAP to build Customer Trust – Virtuos Veracis",
      description:
        "Operations (O) are digital. Our Digital ASAP Services comprise a variety of tools to help organizations acquire potential customers while improving existing customer relationships.",
      keywords: "",
    },
    element: <Digital_Asap />,
    errorElement: <PageNotFound />,
  },
  {
    path: "digital-new/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Digital.New is a Digital Transformation Tool for determining the Maturity in your organization.",
      description:
        "Virtuos Digital.New is a Digital Transformation Tool for determining the Maturity in your organization.",
      keywords: "",
    },
    element: <Digital_New />,
    errorElement: <PageNotFound />,
  },
  {
    path: "digital-new/oracle-service-cloud/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM and Customer Experience | Oracle Service Cloud – Virtuos Digital",
      description:
        "Complete Oracle RightNow portfolio of Service Cloud. Desktop workflows, agent scripting, Oracle CX upgrades. Virtuos has 10+ years of deep expertise in Oracle CX, Oracle Service Cloud serving Retail, Ecommerce, and Travel verticals.",
      keywords: "",
    },
    element: <Oracle_Service />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cx/strategy-design",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "CX Strategy, roadmap to success – Digital Virtuos Digital Ltd.",
      description:
        "At Virtuos, we create and use Moments of Truth to enhance your CX with Industry’s first and most integrated building blocks.",
      keywords:
        "personalization, Moments of Truth, enhance your CX, CX building blocks, CX Team & Governance, consolidate voice of customer, Direct, Indirect and Inferred, Customer Voice sources, Direct VoC, Indirect VoC, Inferred VoC, CX Transformation projects, CX Audit, Customer Experience, Multi-Experience (MX), seven types of CX Projects, Crayonz, Employee Experience (EX), developing CXM strategy, Employee Engagement, Customer Satisfaction, loyalty, advocacy, CX Architecture, CX Maturity, cxdesk, customer experience continuum, Customer Journey Maps, Identify high impact, cracks in CX journey, create CX foundation",
    },
    element: <CX_Strategy_Design />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cx/cx-marries-digital/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CX Marries Digital. CX Is Integral To Marketing – Virtuos Digital",
      description:
        "Virtuos creates an integrated CX.Digital Practice. CX Marries Digital. Customer Experience and Digital Marketing go hand in hand and they are practically inseparable.",
      keywords: "",
    },
    element: <Marries />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/rainbowcx",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "The Everything Customer Experience – RainbowCX",
      description:
        "The Everything Customer – They want multichannel experience. Presenting RainbowCX",
      keywords: "",
    },
    element: <Rainbow />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Experience Program and Customer Journey Mapping | Virtuos Digital Ltd.",
      description:
        "Customer Experience is not a project — it’s a program. We have built this with our ten years of relentless obsession by delivering both technologies and transformation services to more than 100 leading brands around the world",
      keywords:
        "CXNOW program, cx transformation, digital transformation, cx strategy, cx design, digital design, cx program, cx primer, cx audit, cxopia, cx unity, customer journey, state of cx, cxnow pricing, feedback, quality control, realtime rerouting, digital business moments, customer voice, Customer Journey, Cjourney, knowledge management, employee experience, cxdesk, Create memorable experiences, value proposition, employee experience, customer persona, customer experience revolution",
    },
    element: <CXNow />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cx-continuum/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Experience Continuum Services | Business 4.0 Operations – Virtuos Digital",
      description:
        "Customer Experience Continuum As A Service brings unparalleled edge for our customers to keep harvesting on Acquisition, Retention and Efficiency strategies using our Business 4.0 Operational Excellence Models.",
      keywords: "",
    },
    element: <Continuum />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxdesk",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CXDesk | Customer Experience Technology Management – Virtuos Digital",
      description:
        "CXDesk is a diverse, multi-functional approach to optimize CRM investments through technology upgrades, continuous improvement, and integration delivering operational excellence and reduced TCO.",
      keywords: "",
    },
    element: <CXDesk />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/rainbowcx/multix",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Embrace Multiexperience for Improved CX – RainbowCX",
      description:
        "Rainbow CX – Transcend Omnichannel Thinking and Embrace Multiexperience for Improved CX.",
      keywords: "",
    },
    element: <Multimix />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxdesk/unified-desk",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "CXDesk | Customer Experience Unified– Virtuos Digital",
      description:
        "Beauty and brain behind your Customer Experience. It’s a blended, multi-talent and hybrid CX technology management service.",
      keywords: "",
    },
    element: <Unified_Desk />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxdesk/customer-success-stories",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "CXDesk | Customer Success Stories – Virtuos Digital",
      description:
        "Our CXDesk clients are those brands that care for continuous, seamless, and silo-free customer experience and firmly believe that technology plays a pivotal role in improving CX.",
      keywords: "",
    },
    element: <Customer_Success />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/vaves",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Vaves | CX & Digital Transformation Practice in alliance with Oracle",
      description:
        "In Virtuos Vaves we Experience the powerfull Customer Experience with Oracle, Creating Unified Experiences with Qualtrics and Delivering Ultimate Modern CX with CXNOW",
      keywords: "",
    },
    element: <Vaves />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cxprimer/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Experience (CX) Primer | | CX.Digital Transformation – Virtuos Digital",
      description:
        "Customer Experience (CX) Primer is part of the 7C framework at CXNow — the Customer Experience Transformation Program at Virtuos, offering modern CX best practices, business and technology insights, research and advisory services on a continuous basis.",
      keywords: "",
    },
    element: <CxNow_CXprimer />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cxopia",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CXOpia, Understanding the state of Customer Experience (CX) - Virtuos Digital",
      description:
        "CXOpia Framework For The Brands. Knowing the state of CX and brand’s CX Mission is a prerequisite of any engagement model. CXOpia is part of CX Primer, the key aspect of our transformational CX Program – CXNow.",
      keywords: "",
    },
    element: <Cxopia />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cxpyramid",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CX Pyramid, The Governance Model balancing Business and Technology - Virtuos Digital",
      description:
        "CX Is Today An Inverted Pyramid. Leading brands use a different mindset and framework to find innovative approaches to differentiate their brands from the rest.",
      keywords: "",
    },
    element: <Pyramid />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cxdatum",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "CX Datum, Data is the new age fuel for CX – Virtuos Digital",
      description:
        "Sneak peak into data and information taxonomy – Virtuos CXDatum. Data is vital to improve CX and Customer 360 Insights.",
      keywords: "",
    },
    element: <Cxdatum />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/customer-journey/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CJourney | Customer Journey Mapping Services | CX.Digital Transformation – Virtuos Digital",
      description:
        "Virtuos creates Personas to Illuminate and Inform the Customer Experience, Complete design, strategy for customer journey mapping by defining business moments across channels.",
      keywords: "",
    },
    element: <Customer_Journey />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cxunity/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CXUnity.com – Pursuit of building composable enterprise – Virtuos Digital",
      description:
        "Virtuos CX Unity delivers composition. Our Rainbow of CX technologies help organizations transcend omnichannel thinking and embrace multi-experience for improved CX. CX Unity (not to be confused as a technology stack) as a consulting practice",
      keywords: "",
    },
    element: <CX_Unity />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/ex/strategy-design/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Digital focusing on a new mission — EX and CX in Unison – Virtuos Digital Ltd.",
      description:
        "Employee Experience As A Key Strategy for Customer Experinece. EX and CX are not parallel but integrated together to deliver superior brand trust and brand experience.",
      keywords:
        "Employee Experience, EX, Post-COVID economy, EX has a new equation CX, employees with ownership quotient, EX strategy, engineers, mavericks, pilots, caretakers, navigators, Enhancing Employee Engagement, Operational performance, Improve Customer Experience, Improve employee engagement, Impact of employee engagement on customer experience, Enhancing Employee Engagement, Improve the Customer Experience, invest in employee experience",
    },
    element: <EX_StrategyDesign />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/insidex",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "HCM Applications Cloud And Employee Experience (EX) – Virtuos Digital Ltd.",
      description:
        "Highly proliferating #HRTech. Dozens of HR (or HCM) Technologies are rapidly emerging to bring automation, talent development, and employee engagement. Call us today!",
      keywords:
        "HRTech, HCM, Employee Experience, EX, talent development, employee engagement, HCM Software, HR Technology, talent acquisition, learning, performance management, EX Strategy, Technology Strategy, Innovation On (iO), employee journey map, Candidate Engagement to Employee Experience, Ex-Employee Alumni relations, employee’s journey, Candidate Attraction, Candidate Engagement, Applicant Screening and Agreement, Interview and Selection, HR Operations, Onboarding, talent acquisition, modern EX, InsideX HR",
    },
    element: <Insidex />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/w-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Digital WorkPlace Transformation Program – Virtuos Digital",
      description:
        "Virtuos W.Digital WorkPlace Program is a business strategy to boost workforce digital dexterity by creating ExperienceJob and engaging work environment.",
      keywords: "",
    },
    element: <W_digital />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/talentare",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Talentare Business Services | Talent Management Services | Skill management – Virtuos Talentare",
      description:
        "What is Talentare: The pace of innovation in technologies leading to an increase in the gap between the demand and supply of the skill-set required by the organizations.",
      keywords: "",
    },
    element: <Talentare />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/it",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Highly Trusted Enterprise IT Services –  Consultare Virtuos Digital Ltd.",
      description:
        "Enterprise IT Services is a new division at Virtuos Digital spanning Technology Services, Digital Automation to Engineering and Operations. To Know more, call us at 0124 498 5500.",
      keywords:
        "enterprise IT, digital automation, vivacis digital automation, technology services, digital engineering, O.Digital Operations, Virtuos.dev, Virtuos Cloud, Right Apps, Application Services, Optimization across your (Out)Sourcing, Procurement, managed support, business continuity, Vendor Management initiatives, Virtuos Consultare Digital Transformation Practice, Contact Center Solutions, Call Center Solutions, Managed Services, VIVACIS Digital Automation, Development Operations, Managed Services, enterprise collaboration, social networking, content, team collaboration, cloud based messaging",
    },
    element: <IT />,
    errorElement: <PageNotFound />,
  },

  {
    path: "cx-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "CX and Digital Integrated Transformation | Virtuos Digital Ltd.",
      description:
        "CX marries digital. Virtuos delivers integrated Customer Experience (CX) and Digital Transformation Services for growing brands with Virtuos Digital. To know more, call us at 0124 498 5500.",
      keywords:
        "cx marries digital, CX.Digital, cx, customer experience vision, strategy, technology, transformation, cx continuum, strategy is execution, cx is brand, moments of truth, enhance cx, customer experience is not a project – it's a program, customer experience building blocks, introducing cxnow, rainbow cx, experience edge, experience economy, cxdesk, cx and digital maturity, indicial",
    },
    element: <CX_Digital />,
    errorElement: <PageNotFound />,
  },
  // {
  //   path: "smartsheet",
  //   loader() {
  //     return json({
  //       message:
  //         "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
  //     });
  //   },
  //   element: <Smartsheet />,
  // },
  // {
  //   path: "/customers/philips",
  //   loader() {
  //     return json({
  //       message:
  //         "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
  //     });
  //   },
  //   element: <Philips />,
  // },
  // {
  //   path: "/customers/zoom-brandfolder",
  //   loader() {
  //     return json({
  //       message:
  //         "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
  //     });
  //   },
  //   element: <Zoom />,
  // },
  // {
  //   path: "/customers/HP",
  //   loader() {
  //     return json({
  //       message:
  //         "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
  //     });
  //   },
  //   element: <HP />,
  // },
  {
    path: "/w-digital/program",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Digital WorkPlace Technologies and Services Program – Virtuos Digital",
      description:
        "Your Digital Workplace program will be tested throughout every phase of its life-cycle. Welcome to W.Digital Program",
      keywords: "",
    },
    element: <Wdigital_program />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/vivacis",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Digital Transformation at the speed of thought – Virtuos Vivacis",
      description:
        "Virtuos Vivacis is the answer to accelerate your digital transformation at the speed of thought. Vivacis blends AI driven acceleration of Digital Process Automation (DPA) tools with Robotics Process Automation (RPA) tools.",
      keywords: "",
    },
    element: <Vivacis />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/veracis",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "The POST-COVID Outcome Driven Customer Trust Operations – Virtuos Veracis",
      description:
        "Veracis Means Trust: Evolution from CX to customer trust, Develop customer trust as an extension to existing customer experience by addressing issues related to data protection, security, privacy, and the use of information",
      keywords:
        "Veracis Means Trust, Nine characteristics, Experience Trusted Organization, Evolution from CX, customer trust, Develop customer trust, customer experience, data protection, security, privacy, the use of information, Realignment of skills and Talent, Develop new talent , sourcing and management models, crowdsourcing, WAH, gig-economy model, customer management operations, Post-COVID, Experience Portfolio, Focus on key financial metrics, ROI or return on digital investment, CX, quality of service, cost and time to market, Honesty and Truthfulness, Communication, Competency, Transparency, Experience Centric, Financial Viability, Social Responsibility, Innovation, Safety and Privacy, Experience Trust Operation",
    },
    element: <Veracis />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/engineering-services/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM Software and Engineering Services | Design and Development – Virtuos.dev",
      description:
        "At Virtuos, we transform companies with Cloud First to Cloud Only strategies. Armed with Experience Design Thinking DNA our Software Engineering skills in the specialized yet most modern stack.",
      keywords: "",
    },
    element: <Consultare_engineering_services />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cloud",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "The Invincible Cloud from CloudMastermind – Cloud Virtuos Digital Ltd.",
      description:
        "Virtuos Cloud builds and scales 100% experiences. Introducing CloudMastermind. Virtuos Cloud offers 'Business Agile' Professional Services aimed at helping customers achieve success in their Cloud Journey.",
      keywords:
        "IT infrastructure, infrastructure management, New Service Offerings, New Cloud Services, Speed and Agility to Transform, operations and governance, cloud skills, Virtuos — The MSP partner, leading public cloud providers, Public Cloud Offerings, Oracle Cloud, Microsoft Azure, AWS, Salesforce, IBM Cloud, cloud-first and multicloud strategy, cloud adoption maturity, multicloud governance, cloud management processes, multicloud SaaS integration, CloudMastermind",
    },
    element: <Cloud />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/oracle/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "The No. 1 CX and Digital Transformation Solutions: Virtuos and Oracle – Virtuos Digital",
      description:
        "The Powerful Duo. Oracle and Virtuos is a powerful force when it comes to CRM Technology Implementation and Customer Experience (CX) Transformation.",
      keywords: "",
    },
    element: <Oracle />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/microsoft/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos and Microsoft | Dynamics 365 and PowerApps – Virtuos Digital",
      description:
        "Microsoft Ecosystem. Enterprise Choice for decades. Dynamics 360 CRM, PowerApps And Microsoft 365 are fully integrated to unleash the power enterprises needs.",
      keywords: "",
    },
    element: <Microsoft />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/agiloft/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Agiloft Contract lifecycle management (CLM) | Virtuos",
      description:
        "Virtuos Agiloft contract management integrations is the most adaptable software for CLM",
      keywords:
        "gartner ranked CLM, Agiloft India, Magic Quadrant Leader, Leader in CLM, Agiloft and Virtuos Vivacis, Agiloft — The leader in CLM and ITSM team, Virtuos implements CLM, CLM clients, Contract Lifecycle Management, No-Code Platform, flexible licensing model, Pre-built and Custom Integration Methods, customer success, solutions on agiloft platform, biggest reasons for buying CLM, improve efficiency, reduce risk, compliance and risk management",
    },
    element: <Agiloft />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/creatio/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos and Creatio CRM Digital | Digital Process Automation – Virtuos Digital",
      description:
        "Virtuos and Creatio. Accelerate sales, marketing, and customer service of your organization. The Single platform to accelerate sales, marketing, and service.",
      keywords: "",
    },
    element: <Creatio />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/alliances/specialized/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos and Specialized Alliances and Partnerships | Partner with the best – Virtuos Digital Ltd.",
      description:
        "Virtuos has forged partnerships with specialized technology providers in CRM, Customer Experience, Customer Analytics, Contract management, chatbot, workforce optimization, workforce engagement, voice of customer analytics, and predictive analytics. 24(7), agiloft, Domo, bpmonline, verint, and Openmethods",
      keywords:
        "CRM, Customer Experience, Customer Analytics, Contract management, chatbot, workforce optimization, workforce engagement, voice of customer analytics, business process management, bpm, sales crm, marketing crm, service cloud, Customer Engagement, CEM, CX, predictive analytics, active share, search bidding, field service,  24(7), agiloft, Domo, bpmonline, verint, Openmethods, Clicksoftware",
    },
    element: <Alliances_specialized />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/io",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Jump-Start Your Innovation Journey | Innovation On – Xonomy Virtuos Digital Ltd.",
      description:
        "Innovation ON is your customizable framework to design innovation roadmaps and deliver high value business outcomes.",
      keywords:
        "innovation On lab, Innovation On Framework, idea generation to implementation, The Invincible Company, business model canvas, business model innovation, funding model during the COVID, stages to business inovation response, strategy pitfalls, culture pitfalls, process pitfalls, building blocks of innovation on, Reskilling for the new economy, Design Thinking combines Analysis and Intuition, Culture at the Organizations, Explicit Shift from Exploration to Exploitation, design thinking",
    },
    element: <Io />,
    errorElement: <PageNotFound />,
  },
  {
    path: "bpm-cx/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "BPM-CX",
      description: "",
      keywords: "",
    },
    element: <BPM />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/ventures",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Incubation | Ventures – Virtuos Xonomy",
      description:
        "Virtuos Ventures is part of Virtuos Xonomy Business Group. At Virtuos Xonomy, we ideate, incubate, and fund the ventures promoted internally by the employees or the executive management",
      keywords: "",
    },
    element: <Ventures />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/nexorama",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Nexorama New Digital Transformation | Virtuos Digital Ltd.",
      description:
        "Nexorama is the foundation of three essentials: Composable, Fluidible and All Agile. This is our New direction to build Composable Products and Services in the Experience Economy. Call us today at 0124 498 5500.",
      keywords: "",
    },
    element: <Nexorama />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/solutions",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Business Solutions by Function – CRM, Customer Experience (CX) and Employee Experience (EX) – Virtuos Digital",
      description:
        "Business Solutions – CRM, Customer Experience (CX), Employee Experience (EX) from the leading global alliance partners – Oracle, Salesforce, Microsoft, Creatio, Oracle NetSuite amongst others",
      keywords: "",
    },
    element: <Solutions />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/industry",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Industry Solutions for Business – CRM, Customer and Employee Experience – Virtuos Digital Ltd.",
      description:
        "Welcome to the Virtuos Industry Solutions. We are at the beginning of a revolution that is fundamentally changing the way we live, work and relate to one another",
      keywords:
        "Horizontal, Vertical, and Diagonal Edge, The XYZ Framework, vision, ability, Depth. maturity of the transformation, Virtuos Business 4.0,Globalization 4.0, Industrialization 4.0, Digitalization 4.0, Societalization 4.0 (aka Society 5.0), for signature experiences, Banking & Finance, Communications, Consumer Goods, Education, Entertainment, Healthcare, hitech, retail, internet, ecommerce, travel, hospitality, diversified, industry solutions, oracle, salesforce, creatio, microsoft, CRM, Customer Experience, ERP, HCM, CXSuite, Knowledge, WebSelf-Service, Knowlede",
    },
    element: <Industry />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/business4-0",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Business 4.0 Digital Transformation – Virtuos Digital ",
      description:
        "Virtuos Business 4.0 is the confluence of four important factors — The Globalization 4.0, Industrialization 4.0, Digitalization 4.0, and the Societalization 4.0 (aka Society 5.0) for delivering 'signature experiences'",
      keywords:
        "Virtuos Business 4.0, The Globalization 4.0, Industrialization 4.0, Digitalization 4.0, Societalization 4.0 (aka Society 5.0), fourth industrial revolution, agilenterprise, COVID-19, experience centric, agile customer, Post-COVID uncertainties, voc centric, service centric, customer centric, Experience Economy, Virtuoso Economy, competing for customer, business 4.0 kurpu, composed experience flows",
    },
    element: <Business4_0 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/business4-0/agilenterprise/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Business 4.0 | Agilenterprise Strategy – Virtuos Digital",
      description:
        "Business 4.0 Agilenterprise Strategy – The Post-COVID Business 4.0 Strategy at Virtuos",
      keywords: "",
    },
    element: <Agilenterprise />,
    errorElement: <PageNotFound />,
  },
  {
    path: "business4-0/rigour/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Rigour – Virtuos Salesforce Practice and SBU",
      description: "",
      keywords: "",
    },
    element: <Rigour />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/ex-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "EX and Digital Integrated Transformation | Virtuos Digital Ltd.",
      description:
        "EX marries digital. Virtuos delivers integrated Employee Experience (EX), Digital Workplace Program, and Transformation Services Near Me. Get in touch with us, Call us at 0124 498 5500.",
      keywords:
        "Digital Workplace, Modern Office Setup, employee experience, digital, employee journey map, design smart goals, OKRX, customer at heart, CX as a necessary factor, CX strategy, Work anywhere, Experience jobs, crayonz, HCM Cloud technologies, HCM Cloud, Best practices, strategy eats culture for breakfast, culturo framework, workplace culture",
    },
    element: <Ex_digital />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/o-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Modern Post COVID Digital Operating Model — Veracis Customer Trust – O.Digital – Veracis Virtuos Digital Ltd.",
      description:
        "Improve Customer Retention and Growth With a CX Strategy using O.Digital Mastermind. Engage with Virtuos Veracis for running Customer Trust As A Key Differentiator",
      keywords:
        "Customer Management (CM) Operations Strategy, Continuous CM Process Improvement, 100+ CRM Technologies, customerwide harmonics, cx continuum, Return on Value (RoV), Radicalwork Nucleus (RWN), The impact of COVID-19 on cloud management, gigclouds, Operations (O) are digital, O.Digital Services, Digital process Automation, BPO vs O.Digital, Content Orchestration, Outcome Driven Prospecting, Social Sentiment Monitoring, Multi-channel Campaigns, Business Process Outsourcing",
    },
    element: <O_digital />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Customers by Industry – Virtuos Digital Ltd.",
      description:
        "Customer Success Stories: Virtuosos have compulsory Customer Experience (CX) KPI deeply embedded into their DNA. The powerful set of Employee OKRX, where 'X' is for an Experience outcome, is squarely focussed on Customer Success",
      keywords:
        "Virtuos customers, customer @ heart, succeedant, customer success stories, case studies, customers by industry, List of customers, flipkart, makemytrip, thomas cook, myntra, standard chartered bank, craftsvilla, India first, tvs credit, BARC, customers by Industry, verticals, case studies by industry, OKRX, employee OKRX",
    },
    element: <Customers />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Careers | Job Opportunities | Seek rewarding careers – Careers Virtuos Digital Ltd.",
      description:
        "Explore enriching and rewarding career with Virtuos, a leading cx and digital integrated transformation services consultancy. Job opportunities in multiple locations in a variety of fields – CRM, CX, EX, Sales, Software Development, IT, Digital Marketing, and more.",
      keywords:
        "Jobs, careers, Apply for jobs, salesforce consultants, oracle cx consultants, jobs for developers, software developers, launchpad program, genie program, springboard program, jobs for freshers, campus placements, on campus jobs, jobs for engineers, Jobs for MBAs, Jobs for consultants",
    },
    element: <Careers />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/faq",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Frquently Asked Questions (FAQ) on Careers – Virtuos Careers",
      description:
        "Frquently Asked Questions (FAQ) about careers at Virtuos. Apply online for select positions available. How to apply online for job at Virtuos. Do you have any questions regarding job positions, contact Virtuos",
      keywords: "",
    },
    element: <Careers_FAq />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/corporate/story",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "The Story of Virtuos | History and Background | Corporate Information – Virtuos Digital Ltd.",
      description:
        "The story of Virtuos – Virtuos [vur-choo-oh s] means Elite Experts. We were inspired by the power of Cloud and what it could offer to business applications — especially CRM and Customer Experience.",
      keywords: "",
    },
    element: <Corporate_Story />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/corporate/team",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "About us | Corporate information | Virtuos Team members – Virtuos Digital",
      description:
        "Virtuoso Team DNA: We assembled some of the top performing teams and formed Virtuos with the clear intent of delivering truly outstanding Customer Experience (CX) Strategy, Digital Transformation Consulting and Experience Cloud Ecosystems.",
      keywords: "",
    },
    element: <Corporate_Team />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/news",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos News Center | Latest news and press releases – News & Events Virtuos Digital Ltd.",
      description:
        "Virtuos News Center. News and Press Releases. What's new with Virtuos. Latest news at Virtuos on business, technology, digital marketing and innovation",
      keywords:
        "news, latest information, what's new at Virtuos, Virtuos news, Virtuos press releases, News center, Virtuos News and Information",
    },
    element: <News />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/virtuosoship",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuosoship – The state of being a virtuoso; the activities of a virtuoso – Virtuos Digital Ltd.",
      description:
        "Virtuoso needs new state to be better. Skills, Heuristics, Innovation, and Productivity (SHIP) are dynamic and challenged constantly.",
      keywords: "",
    },
    element: <Virtuosoship />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/investors",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Transforming the company through turbulent times | Investors – Virtuos Digital Ltd.",
      description:
        "Bootstrapped Company with “Invincible Model” to thrive during the COVID and Post COVID period.",
      keywords: "",
    },
    element: <Investors />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/community",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Community of Customers, Employees and Partners– Virtuos Digital",
      description:
        "Virtuos Foundation, 1-2-3 Edge is part of Virtuism’s Creating Shared Value (CSV) initiative to help the society. 1-2-3 Edge program, Non-Profit Organizations will receive upto 70% discount on list prices for our Professional Services, and Engineering/software development",
      keywords: "",
    },
    element: <Community />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/oracle-netsuite/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos and Oracle NetSuite | The ERP Practice – Virtuos Vivacis",
      description:
        "Virtuos Vivacis ERP Practice. ERP helps organizations deliver better Customer Experience (CX) with the help of integrated billing, Financial systems, Order Management and Production Management.",
      keywords: "",
    },
    element: <Oracle_nestitute />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/corporate/experience-edge",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Experience Edge | CRM and CX Domain Expertise | Advantage – Digital Ltd.",
      description:
        "Virtuos designs and delivers CRM and CX software from leading brands. Transforms business models across brand and commerce. Experience Edge Portfolio includes Pace Layered Umbrella System (PLUS), Digital Etiquette, top-notch alliances, and happiests culture.",
      keywords:
        "experience edge, digital etiquette, customer @ heart, idea hunters, crm and cx, domain expertise, crm expertise, cx expertise, virtuos strategy, digital transformation, experiencejob, oracle cx, verint cem, microsoft dynamics, salesforce crm, CX",
    },
    element: <Corporate_Experience_Edge />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/succeedant",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Succeedant, Virtuos Customer Success Program – Virtuos Digital Ltd.",
      description:
        "At Virtuos, we reimagine Customer Success by naming it as 'Succeedant' and creating a framework of 'Continuous Success' with a series of programs one after the other because success has no full stops and only commas.",
      keywords:
        "succeedant, succeeding continuously, customer success program, success has no full stops, continuous success, customer at heart, tomorrowing, customer success management, Virtuos Agilenterprise Strategy, Virtuosoship eDexterity, All agile opetations, invincible 360, Customer Success Maturity, insights & actions, continuous innovation, CSM Objectives and Goals",
    },
    element: <Succeedant />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/experience-new/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Expedience Advisory and Management Consultancy – Virtuos Digital",
      description: "The Transformational Reset Strategy By Virtuos Expedience",
      keywords: "",
    },
    element: <Exp_new />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/insights",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Research and Customer Insights | Whitepapers and Latest Technology Trends – Virtuos Digital Ltd.",
      description:
        "Virtuos offers deep research and customer insights. Articles and white papers from industry's leading journalists and sources including publications.",
      keywords:
        "customer insights, customer driven research, best practices, bpm, crm, cx, artificial intelligence, customer success, customer stories, case studies, business insights, technology insights, crm insights, cx insights",
    },
    element: <Insights />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/opportunities",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Careers with Virtuos | Explore and Apply for jobs online – Virtuos Careers",
      description:
        "Virtuos offers exciting career opportunities for experienced professionals, graduates in engineering. Opportunites are for Sales, Digital Marketing, Web/Graphics, Market Research, HR, Content, IT/Admin, and Catalogue Management",
      keywords: "",
    },
    element: <Careers_Opportunities />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/.net-web-developer",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs",
      description:
        ".Net Development: The role also accords a deep understanding of Customer's business processes, and technology adaptation providing an ample room for candidate's overall growth",
      keywords: "",
    },
    element: <NetDeveloper />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/business-development-executive",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Career Opportunities | Business Development Executive – Virtuos Jobs",
      description:
        "We are looking for dynamic and smart go-getters to be part of Experience Cloud Business. At Virtuos, we drive vivid experiences through cloud and mobility platforms that are unique and highly differentiated",
      keywords: "",
    },
    element: <BusinesDev />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/business-head",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Career Opportunities | Business Head – Virtuos Jobs",
      description:
        "Business Head for CRM: We are looking for dynamic and smart go-getters to be part of Experience Cloud Business. At Virtuos, we drive vivid experiences through cloud and mobility platforms that are unique and highly differentiated",
      keywords: "",
    },
    element: <BusinessHead />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/crm-lead-consultant",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Career Opportunities | CRM Lead Consultant - Virtuos Jobs",
      description:
        "CRM Lead Consultants: We are looking for mid to senior specialists who can work on key projects to deliver top tier solutions from design and configuration to integration and testing",
      keywords: "",
    },
    element: <LeadConsultant />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/crm-solution-consultant",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Career Opportunities | CRM Solution Consultant - Virtuos jobs",
      description:
        "CRM Solution Consultants: This role requires experience of successfully delivering CRM projects in large corporate environments and requires someone who understands how business works and how CRM solutions can support the business",
      keywords: "",
    },
    element: <SolutionConsultant />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/drupal-developer",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Career Opportunities | Drupal Developer - Virtuos jobs",
      description:
        "Drupal Developers: This position requires a combination of programming skills (PHP, HTML, CSS, JS, etc.) and aesthetics (understanding element arrangements on the screen, the color and font choices, and so on)",
      keywords: "",
    },
    element: <Drupal />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/magento-developer",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Career Opportunities | Magento Developer - Virtuos Jobs",
      description:
        "Magento Developers: We are looking for an experienced full stack Magento Developer with strong front end experience to lead and/or assist in the architecture, design, implementation,and maintenance of Magento eCommerce sites",
      keywords: "",
    },
    element: <Magento />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers/jobs/web-graphic-designer",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Career Opportunities | Web & Graphics Designer - Virtuos Jobs",
      description:
        "Web and graphics Designers: We are looking for a talented Web & Graphics Designer to create amazing user experiences. The ideal candidate should have an eye for clean and artful web design. They should also have superior user interface design skills",
      keywords: "",
    },
    element: <Graphic />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/jobs",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Career Opportunities in CRM, CX, Engineering, and Development – Virtuos Careers",
      description:
        "Virtuos offers career opportunities in Sales, Marketing, Consulting, Salesforce CRM, Oracle CX, Microsoft Dynamics, and Verint CEM amongst other applications. We also offer opportunities in Engineering and Development on various platforms such as Dot.Net, Java, JavaScript, Angular JS, and Other full stack applications",
      keywords: "",
    },
    element: <Career_Job />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/webinars",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Webinars on CRM, Customer Experience | Attend Webinar – Virtuos Webinars",
      description:
        "Latest webinars on CRM and Customer Experience. Webinars by Experts in CRM and Customer Experience. Latest webinar on CRM, register for webinars, and download content from webinars",
      keywords: "",
    },
    element: <Webinars />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/branding",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Branding Guidelines and Media Kit – Virtuos Digital",
      description:
        "The Virtuos logo is one of our most valuable corporate assets. It’s among the most recognized corporate identities in the world, uniquely distinguishing us from our competitors and other companies",
      keywords: "",
    },
    element: <Branding />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/technology-services",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Cloud and Digital Technology Services for CRM and CX | Applications Group – Virtuos Consultare",
      description:
        "Combine high-performance Virtuos cloud infrastructure with a broad range of Artificial Intelligence (AI) APIs to power your enterprise software applications, and modernize legacy software.",
      keywords: "",
    },
    element: <Consultare_technology_services />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/applications-group",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Applications group | CRM and Customer Experience Professional Services – Virtuos Consultare",
      description:
        "Engineering & Professional Services for Customer and Employee Engagement Technologies, Platforms, and Ecosystems.",
      keywords: "",
    },
    element: <Consultare_Applications_Group />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/indicial",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Indicial CX & Digital Maturity and Diagnostics | CX.Digital – Virtuos Digital",
      description:
        "Integrated Customer experience and Digital Transformation (CX.Digital Practice) is a discipline that requires an ongoing focus.",
      keywords: "",
    },
    element: <Indicial />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/flipkart-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Flipkart Oracle CX case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. Virtuos implemented Oracle CX at Flipkart India, a leading ecommerce company. Virtuos expertise in Oracle CX for ecommerce",
      keywords: "",
    },
    element: <Flipkart_Case_Study />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/customers/standard-chartered-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Standard Chartered Bank CX case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. Standard chartered bank, India and APAC implements KANA Customer Experience Software for contact center. Virtuos implements CX at SCB",
      keywords: "",
    },
    element: <Standard_Chartered_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/makemytrip-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | MakeMyTrip Oracle CX case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. A leading travel company — MakeMyTrip.com implements Oracle CX. Virtuos implements Oracle CX for MakeMyTrip, CX for travel industry",
      keywords: "",
    },
    element: <Makemytrip_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/indiafirst-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | India first CRM case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. India First Case study, Insurance Industry, CRM for Insurance vertical, Customer Experience for Insurance",
      keywords: "",
    },
    element: <Indiafirst_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/agiloft-clm-for-internet-co-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Agiloft CLM for a leading Internet Company",
      description:
        "A leading Internet brand Company from Arvind Group implements Agiloft Contract Lifecycle Management from Virtuos Vivacic",
      keywords: "",
    },
    element: <Agiloft_Clm_For_Internet />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/creatio-crm-for-power-co-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Creatio CRM for a leading Power Company",
      description:
        "A leading Power Generation Company implements Creatio CRM from Virtuos Vivacis",
      keywords: "",
    },
    element: <Creatio_Crm_For_Power_CO_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/tvs-credit-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | TVS Credit CRM case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. TVS Credit CRM case study. Virtuos implements Oracle RightNow CX for leading NBFC Company — TVS Credit",
      keywords: "",
    },
    element: <Tvs_Credit_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/thomascook-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Thomas Cook Oracle CX case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. Virtuos implements Oracle RightNow CX for Thomas Cook India, Oracle CX modules, Oracle CX implementation, CX for travel industry, Oracle Service cloud implemented at Thomas cook india",
      keywords: "",
    },
    element: <ThomasCookInidia />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/entertainment-company-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Entertainment vertical CX case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. Entertainment Industry, Virtuos demonstrates strong expertise in Oracle CX and Oracle Rightnow service cloud",
      keywords: "",
    },
    element: <Entertainment_compnay />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/myntra-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Myntra Oracle CX case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. Virtuos implements Oracle rightnow CX for Myntra, a leading lifestyle apparel company, Myntra implements Oracle CX",
      keywords: "",
    },
    element: <Myntra />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/crm-cx/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM Implementation, CRM Cloud Technologies, CRM Platform Consulting, CRM, CX Practice – Virtuos Consultare",
      description:
        "100+ happy customers, and counting. CRM Implementation, CRM Cloud Technologies, CRM Platform Consulting. CRM Sales, CRM Service, CRM Marketing and CRM Commerce",
      keywords: "",
    },
    element: <Consultare_Crm_Cx />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/pace-layered/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Pace Layered Umbrella Strategy | Systems of Engagement – Virtuos Consultare",
      description:
        "The Pace Layered Umbrella Strategy. Virtuos Strategy Framework for Customer Success.",
      keywords: "",
    },
    element: <Consultare_Pace_Layered />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/platform-ecosystem/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Salesforce Platform & Apps, Oracle Platform As A Service, Android and IOS Platforms – Virtuos Consultare",
      description:
        "Virtuos Platform Services include building Apps with Salesforce Lightning App builder; Oracle Cloud or Oracle Cloud at Customer.",
      keywords: "",
    },
    element: <Consultare_Platform_Ecosystem />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/consultare/experience-design/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Experience Design Consulting | CRM and Customer Experience – Virtuos Consultare",
      description:
        "Experience Design comes from Digital Design Thinking foundation which has a strong ethnographic focus on people with unique mindsets — empathy for mindfulness and collaboration.",
      keywords: "",
    },
    element: <Consultare_Experience_Design />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/experience-job/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "ExperienceJob | Democratization of work with digital dexterity – Virtuos Careers",
      description:
        "Creating Experiencejob. We applied design thinking and came up with an idea ExperienceJob — something along the lines of Customer Experience.",
      keywords: "",
    },
    element: <Career_Expereince_Job />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/culture/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Culture and Values | Happiests culture creating Experience Jobs – Virtuos Careers",
      description:
        "Virtuos values and Virtuos culture are guiding forces behind Virtuos existence. Our values are Virtuosity, Veracity and Velocity.",
      keywords: "",
    },
    element: <Career_Culture />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/life-at-virtuos",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Life at Virtuos | A day in the life of Virtuosity | Work Life Balance – Virtuos Careers",
      description:
        "Life at Virtuos. Virtuos offers great work-life balance, flexible timings and great empowerment. Offer immense opportunities to upgrade skills and help employees professionally",
      keywords: "",
    },
    element: <Life_at_virtuos />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/training-development",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Employee Training and Development | Virtuosoship EDexterity – Virtuos Careers",
      description:
        "Virtuosoship— the Experiential Teaching coupled with academic pedagogy. Virtuos offers training and development programs on Salesforce CRM, Oracle CX, Verint CEM, Microsoft Dynamics CRM, and other programming languages: Dot.Net, Javascript, Angular JS",
      keywords: "",
    },
    element: <Training_Developments />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/happiests-culture",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Happiests Culture and Work Environment – Virtuos Careers",
      description:
        "It’s not just a happy working environment; it’s your own space — where you deliver happiness to customers. The arts and other manifestations of human intellectual achievement collectively regarded as happiests culture.  Virtuos offers growth prospects, continuous innovation, fair play, and happy work culture",
      keywords: "",
    },
    element: <Happiest_culture />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/employee-benefits",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Employee benefits | Employee Incentives – Virtuos Careers",
      description:
        "Virtuos offers awesome remuneration and additional benefits & perks to match the best in the industry. Benefits include maternity and paternity leaves, Optional health insurance, and time off for vacation",
      keywords: "",
    },
    element: <Employee_Benefit />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/360Outlook",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "360 Degree Outlook | Employee Performance Reviews Methodology – Virtuos Careers",
      description:
        "Virtuos 360 Degree Outlook — employee performance review process. Created framework of ABCDE — Attitude, Behaviour, Competency, Diligence, Experiential Outcomes measured quarterly.",
      keywords: "",
    },
    element: <Outlook />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/careers/virtuos-alumni",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos.club | Employee Alumni Association – Virtuos Careers",
      description:
        "Virtuos.club is an association of Virtuos employees. At Virtuos.club, employees can network with fellow members and seek insights. Other benefits include Reunion Meets, career opportunities, advisory services.",
      keywords: "",
    },
    element: <Virtuos_Alumni />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/all-agile",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "All Agile Framework – Virtuos Digital",
      description:
        "Virtuos embarks on Business 4.0 Strategy applying All Agile Principles to renew its vision during the Post COVID Business",
      keywords: "",
    },
    element: <All_Agile />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/invincible",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Becoming An Invincible Company – Virtuos Digital ",
      description:
        "The Invincible Company explores the future, while excelling at exploiting the present.The Invincible company cultivates an innovation and execution culture that lives in harmony under the same roof. It competes on superior business models and transcends traditional industry boundaries",
      keywords: "",
    },
    element: <Invincible />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/vuca",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "VUCA | The New Normal – Virtuos Digital",
      description:
        "Pandemics aren't your usual business disruption. Apply Virtuos VUCA - Volatility, Uncertainty, Complexity, and Ambiguity as a new framework",
      keywords: "",
    },
    element: <Vuca />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/vuca/covid",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "VUCA Framework for COVID-19 – Virtuos Digital",
      description:
        "Transforming in tough times, The world has not come to an end. Pandemics aren't your usual business disruption",
      keywords: "",
    },
    element: <Vuca_Covid />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/io/design-thinking",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Design Thinking View for Digital Transformation – Virtuos Xonomy",
      description:
        "A view of design thinking for digital transformation by Virtuos Innovation On",
      keywords: "",
    },
    element: <IoDesignThinking />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/io/lab",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Why Innovation is Crucial for Business Resilience and Growth",
      description:
        "Explore the benefits of prioritizing innovation in your business and learn how to harness its power to drive results and achieve success.",
      keywords: "",
    },
    element: <Lab />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/io/virtuos/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Jump-Start Your Innovation Journey | Innovation On – Virtuos Xonomy",
      description:
        "Innovation ON is your customizable framework to design innovation roadmaps and deliver high value business outcomes.",
      keywords: "",
    },
    element: <Virtuos />,
    errorElement: <PageNotFound />,
  },

  {
    path: "o-digital/cx-continuum/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Experience Continuum Services | Business 3.0/4.0 Operations - Virtuos Consultare",
      description:
        "Customer Experience Continuum As A Service brings unparalleled edge for Virtuos customers to keep harvesting on Acquisition, Retention and Efficiency. 247 business outcomes, cxdesk management helps organizations in customer acquisition and retention.",
      keywords: "",
    },
    element: <O_Continnum />,
    errorElement: <PageNotFound />,
  },
  {
    path: "o-digital/digital-asap/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Digital ASAP - The CX and Digital Transformation Continuum program - Virtuos O.Digital",
      description:
        "Operations (O) are digital. Virtuos O.Digital Services comprise the processes linking an organization with its existing customers and prospects. We bring strategy framework: Harvest; Amplify; Retention and Efficiency (HARE).",
      keywords: "",
    },
    element: <O_Digital_Asap />,
    errorElement: <PageNotFound />,
  },
  {
    path: "oracle/oracle-service-cloud/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "CRM and Customer Experience | Oracle Service Cloud – Virtuos Digital",
      description:
        "Complete Oracle RightNow portfolio of Service Cloud. Desktop workflows, agent scripting, Oracle CX upgrades. Virtuos has 10+ years of deep expertise in Oracle CX, Oracle Service Cloud serving Retail, Ecommerce, and Travel verticals. ",
      keywords: "",
    },
    element: <Oracle_Service_Cloud />,
    errorElement: <PageNotFound />,
  },
  {
    path: "survey",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos | High performance Digital Transformation Consulting Services",
      description:
        "Virtuos is a leading Digital Transformation Company providing strategy, consulting, and technology engineering services for CRM, Customer Experience (CX), and Employee Experience (EX)",
      keywords: "",
    },
    element: <Survey />,
    errorElement: <PageNotFound />,
  },
  {
    path: "terms-of-use",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Website Terms and Conditions of Use – Virtuos Digital Ltd.",
      description: "Virtuos.com Website terms and conditions of use",
      keywords:
        "virtuos.com, virtuos website, website usage guidelines, website policies, terms and conditions",
    },
    element: <Terms_Of_Use />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/thankyou",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Thank You",
      description: "",
      keywords: "",
    },
    element: <Thankyou />,
    errorElement: <PageNotFound />,
  },
  {
    path: "viraata",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Business Solutions and Services for Government, Public Sector, and Public Institutions – Virtuos Viraata",
      description:
        "Virtuos enters Government Business serving Public Sector and Institutional customers with Viraata Consulting Solutions and Services",
      keywords: "",
    },
    element: <Viraata />,
    errorElement: <PageNotFound />,
  },
  {
    path: "vivacis/dpa",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "BPM, Digital Process Automation and Customer Experience (CX) – Virtuos Vivacis",
      description:
        "Virtuos Vivacis and Creatio bring Digital Process Automation Suite. BPM and Customer Experience practice by Virtuos Vivacis; Integrated Digital Process Automation Suite",
      keywords:
        "Creatio, Virtuos Vivacis, BPM, Business Process Management, BPM for CRM, BPM for CX, BPM for Sales, BPM for Service, BPM for Marketing, BPM.CX, Dynamic Case Management, BPM Leader, BPM Practice, Creatio Implementation, Creatio Apps, BPM CRM, CRM and BPM, Creatio Studio, Creatio Apps, Creatio and Virtuos",
    },
    element: <Vivacis_Dp />,
    errorElement: <PageNotFound />,
  },
  {
    path: "vlocis",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Digital Transformation is at the speed of thought – Virtuos Vlocis",
      description:
        "Virtuos Vlocis is the answer to accelerate your digital transformation at the speed of thought. Vlocis blends AI driven acceleration of Digital Process Automation (DPA) tools with Robotics Process Automation (RPA) tools.",
      keywords: "",
    },
    element: <Vlocis />,
    errorElement: <PageNotFound />,
  },
  {
    path: "community/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Community of Customers, Employees and Partners– Virtuos Digital",
      description:
        "Virtuos Foundation, 1-2-3 Edge is part of Virtuism’s Creating Shared Value (CSV) initiative to help the society. 1-2-3 Edge program, Non-Profit Organizations will receive upto 70% discount on list prices for our Professional Services, and Engineering/software development",
      keywords: "",
    },
    element: <Community />,
    errorElement: <PageNotFound />,
  },
  {
    path: "zidd",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "The Digital Dexterity Platform for E-Learning New Skills - Virtuos ZIDD",
      description:
        "Virtuos Zidd is the most modern digital dexterity platform for e-Learning new skills. Rapid Automation and Artificial Intelligence (AI) are creating new demand for new skills from workforce on a continuous basis. ",
      keywords: "",
    },
    element: <Zidd />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/vivacis/dpa/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "BPM, Digital Process Automation and Customer Experience (CX) – Virtuos Vivacis",
      description:
        "Virtuos Vivacis and Creatio bring Digital Process Automation Suite. BPM and Customer Experience practice by Virtuos Vivacis; Integrated Digital Process Automation Suite",
      keywords: "",
    },
    element: <VivacisDpa />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/nexorama-strategy",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Nexorama Strategy to Diversify Into New Areas – Virtuos News &amp; Events",
      description:
        "Nexorama will develop the next generation of utility tools for modern businesses, startups, and midsized firms to accelerate their operations rapidly and affordably using both low-code no-code technology and composable products",
      keywords: "",
    },
    element: <Nexorama_strategy />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cxaudit",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "CX Audit | Customer Experience Audit Services – Virtuos Digital",
      description:
        "CX Audit is one of the seven building blocks of CXNow – The Customer Experience Transformation Program at Virtuos. Virtuos CX Audit helps in improving Net Promoter Score, track customer satisfaction, Brand advocacy index.",
      keywords: "",
    },
    element: <Cx_Audit />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/cxprism",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Design Thinking for Customer Experience | CX Prism – Virtuos Digital",
      description:
        "CX Prism Design Thinking is built on three core foundations when it comes to delivering breakthrough customer experiences.",
      keywords: "",
    },
    element: <Cxprism />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/cxnow/customer-voice/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer Voice | Voice of the Customer Program | CX.Digital Transformation – Virtuos Digital",
      description:
        "CX leaders identify Voice of the Customer (VoC) as their most preferred and outcome driven technology to invest in Customer Experience (CX) transformation.",
      keywords: "",
    },
    element: <Customer_Voice />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/flipkart-case-study",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Flipkart Oracle CX case study – Virtuos Digital",
      description:
        "List of Virtuos customers, customer success stories, case studies, customers by industry at Virtuos. Virtuos implemented Oracle CX at Flipkart India, a leading ecommerce company. Virtuos expertise in Oracle CX for ecommerce",
      keywords: "",
    },
    element: <Flipkart_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/xonomy/aury",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Aury AI powered Chatbot – Virtuos Xonomy",
      description:
        "Presenting AURY. AI Conversational Systems. Patented NLP and machine learning (ML) Technologies.",
      keywords: "",
    },
    element: <Exonomy_Aura />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/experience-cloud",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Experience Cloud | Experience Economy Business – Virtuos Xonomy",
      description:
        "Virtuos Experience Cloud is an integrated ecosystem of hubs, platforms and suites connected on a common thread to deliver personalized, superior and contextual experiences to customers, employees, and other constituents.",
      keywords: "",
    },
    element: <Expereince_Cloud />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/experience-cloud/aury/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Experience Cloud | Digital | Artificial Intelligence - Virtuos Xonomy",
      description:
        "Experience Cloud is very important practice at Virtuos Xonomy. Virtuos Experience Cloud is an integrated ecosystem of hubs, platforms and suites connected on common thread to deliver personalized, superior and contextual experiences to customers, employees, and other constituents",
      keywords: "",
    },
    element: <Expereince_Cloud_Aury />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/experience-crowd",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Experience Crowd | Experience Economy Business – Virtuos Xonomy",
      description:
        "Experience Crowd – Mass collaboration is the future of competitive advantage in business. Experience Cloud is Mass Collaboration and Experience Economy Business.",
      keywords: "",
    },
    element: <Expereince_Crowd />,
    errorElement: <PageNotFound />,
  },

  {
    path: "experienceo/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "The rise of ExperienCEO – Virtuos Xonomy",
      description:
        "Virtuos Xonomy. When CX and EX are in symphony, the traditional CEOs need to look beyond 'Chief Executive' to 'Chief Experience'.",
      keywords: "",
    },
    element: <Experienceo />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/electronics-company-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Electronics giant CX case study – Virtuos Digital",
      description:
        "Virtuos implements Oracle CX — service cloud at one of the leading electronics giant based in China with global presence. Oracle Rightnow CX is implemented by Virtuos across channels for consumer electronics giant in China.",
      keywords: "",
    },
    element: <Electronics_Company_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/fabindia-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Fabindia CLM case study – Virtuos Digital",
      description:
        "Virtuos Customer Success Stories | Fabindia CLM case study – Virtuos Digital.",
      keywords: "",
    },
    element: <Fabindia_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/giftcart-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Fabindia CLM case study – Virtuos Digital",
      description:
        "Virtuos Customer Success Stories | Fabindia CLM case study – Virtuos Digital.",
      keywords: "",
    },
    element: <Giftcart_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/hdfc-life-insurance-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | HDFC Life Automation case study – Virtuos Digital",
      description:
        "Virtuos Customer Success Stories | HDFC Life Automation case study – Virtuos Digital.",
      keywords: "",
    },
    element: <Hdfc_Life_Insurance_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/hike-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Hike CLM case study – Virtuos Digital",
      description:
        "Virtuos Customer Success Stories | Hike CLM case study – Virtuos Digital.",
      keywords: "",
    },
    element: <Hike_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/jade-global-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos | Customer CLM Success Story for Leading IT Service Company",
      description:
        "Integration of Salesforce with Agiloft via Dell Boomi case study of trusted IT services providers.",
      keywords: "",
    },
    element: <Jade_Global_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/kgisl-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Customer CLM Success Story for Leading Software Company | Virtuos Digital",
      description:
        "Agiloft Contract Lifecycle Management (CLM) Case study of Industry Leading Software company.",
      keywords: "",
    },
    element: <Kgisl_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/niit-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | NIIT CRM case study – Virtuos Digital",
      description:
        "Virtuos Customer Success Stories | NIIT CRM case study – Virtuos Digital.",
      keywords: "",
    },
    element: <Niit_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/customers/securitas-case-study/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Customer Success Stories | Securitas CLM case study – Virtuos Digital",
      description:
        "Virtuos Customer Success Stories | Securitas CLM case study – Virtuos Digital.",
      keywords: "",
    },
    element: <Securitas_Case_Study />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/safe-harbour/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Website Safe harbor Statement – Virtuos Digital Ltd.",
      description: "Virtuos.com Safe harbour statement and guidelines.",
      keywords:
        "safe harbor statement, website policies, forward looking statements, website privacy, website guidelines, website, use of website, website terms, website conditions, copyright, web policies, products featured, statements, website cookies",
    },
    element: <Safe_Harbour />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/right-apps/",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "RightApps Mobile App Development | Android | IOS | Progressive Web Apps – Virtuos.dev",
      description:
        "Welcome to the future where gen-next mobile apps (aka RightApps) will be useful and relevant for the foreseeable period.",
      keywords: "",
    },
    element: <Right_apps />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/privacy-policy",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Website Privacy Policy Statement – Virtuos Digital Ltd.",
      description:
        "Virtuos website policy statement, terms and conditions of using the content on Virtuos.com and affiliate sites.",
      keywords:
        "virtuos website privacy, privacy policy framework, data, virtuos website usage guidelines, terms and conditions of using website, virtuos.com ",
    },
    element: <Privacy_Policy />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/announcing-4.0-as-a-strategy",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Announcing 4.0 As A Strategy on 12th Anniversary – Virtuos News & Events",
      description:
        "Virtuos Digital announces “4.0 As A Strategy” to spearhead the growth of the company into new domains, geographies, and technologies.",
      keywords: "",
    },
    element: <Announcing_Strategy />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/coronavirus-impact-on-business",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Coronavirus impact on business, VUCA Continuity Framework – Virtuos News & Events",
      description:
        "Virtuos has used this opportunity to beef up its offerings by revamping its product portfolio strategy, building new competencies in CRM and CX Technologies, and researching into other areas of Digital Workplace, IoT, Digital Twin, and Rainbow of CX technologies.",
      keywords: "",
    },
    element: <Coronavirus_Impact_On_Business />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/customer-experience-meet-2012",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Solutions announced today that it successfully hosted the first-ever Virtuos 2012 Customer Experience Meet with Oracle RightNow — The leader in Customer Experience (CX) Solutions.",
      keywords: "",
    },
    element: <Customer_Experience_Meet_2012 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/gartner-global-marketing-summit-2015",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos CEO Attends Gartner's Global Marketing Summit in San Diego – Virtuos News & Events",
      description:
        "Gartner has for the first time launched a highly focused Marketing Summit by inviting more than 1000 Global Marketing Leaders.",
      keywords: "",
    },
    element: <Gartner_Global_Marketing_Summit_2015 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/giftcart.com-launches-its-beta-commerce-site",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Shaloo Reddi and Kristina Hermanns have founded Giftcart.com, an ecommerce company into gifting experiences. Giftcart has also recruited three senior leaders with their educational background – IIT, Delhi, IIM Ahmedabad, Asian Institute of Management (AIM), and Manila to supervise the marketing, operations and catalogue management.",
      keywords: "",
    },
    element: <Giftcart_Launches_Its_Beta_Commerce_Site />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/maximizer-virtuos-announces-strong-partnership",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos and Maximizer came up with Press releases announcing their stronger partnership for the India region. After implementing one of the largest projects of Maximizer CRM at GE Healthcare, both Virtuos and Maximizer have reinforced their commitment to Indian market.",
      keywords: "",
    },
    element: <Maximizer_Virtuos_Announces_Strong_Partnership />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/oracle-acquires-rightnow-technologies",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Oracle to acquire RightNow Technologies (Nasdaq: RNOW) for US $1.5 Billion (approximately), a leader in Cloud based Customer Experience (CX) Solutions with Over 2000+ customers including some of the biggest names such as British Telecom, Toshiba, Sony, Electronic Arts (EA) to name a few.",
      keywords: "",
    },
    element: <Oracle_Acquires_Rightnow_Technologies />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/oracle-welcomes-virtuos-into-the-oracle-partner-network",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos becomes Gold Certified Oracle Partner joining Oracle Partner Network (OPN) with specialization in RightNow CX Cloud Service Solutions.",
      keywords: "",
    },
    element: <Oracle_Welcomes_Virtuos />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/news/rightnow-technologies-honours-virtuos",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos attends the Annual APAC Summit hosted by RightNow Technologies (Nasdaq: RNOW) with partners all over Asia",
      keywords: "",
    },
    element: <Rightnow_Technologies_Honours_Virtuos />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/unleashing-business-4-strategy",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Unleashing Business 4.0 Strategy for Digital Transformation – Virtuos News & Events",
      description:
        "Virtuos, the CX and Digital Transformation Consultancy embarks on significant growth using Business 4.0 — a strategy powered by the confluence of Industrialization 4.0, Globalization 4.0, and Digitalization 4.0",
      keywords: "",
    },
    element: <Unleashing_Business_4_Strategy />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/venky-vijay-speaks-to-the-press-at-oracle-cloud-event",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos | Venky Vijay speaks to the press at the Oracle Cloud Event in Bengaluru",
      description: "",
      keywords: "",
    },
    element: <Venky_Vijay_Speaks_To_The_Press />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/verint-acquires-kana-software",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Verint Acquires KANA Software – Virtuos News & Events",
      description:
        "All the KANA customers served by Virtuos will continue to be supported by Verint without any disruption. Verint and Virtuos will be partnering to cater to the customers as per the agreement with KANA Software",
      keywords: "",
    },
    element: <Verint_Acquires_Kana_Software />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-acquires-enforce.com",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos acquired Enforce.com, a new digital asset currently valued at more than US$50,000 as per valuate.com to develop and launch as Strategy & Business Execution ExperienceCloud",
      description:
        "enforce.com, the single dictionary, valuation, US$500k, Strategy & Business Execution, ExperienceCloud, Virtuos acquired, HCM, CRM, ERP, BPM, BI/Analytics, EPM, nexus of forces — Cloud, Social, workforce, Mobility, complex algorithm, venky Vijay Reddi, next generation business",
      keywords: "",
    },
    element: <Virtuos_Acquires_Enforce />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-announces-post-covid-businesses-xonomy-and-brandexpo",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Announces Post-COVID Business: Xonomy and BrandExpo – Virtuos News & Events",
      description:
        "Virtuos announced the first two Post-COVID businesses that promise to deliver new services to customers. XONOMY, a new name for Virtuos Global Conferences and Events, will be live with its first All Digital Event in November 2020",
      keywords: "",
    },
    element: <Virtuos_Announces_Post_Covid_Businesses />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-attends-bpmonline-global-partner-summit-accelerate-2018",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Team on Tour Attending Global Summits – Virtuos News & Events",
      description:
        "Virtuos has signed a partnership with BPM’Online, a leading Midsized CRM Player catering to over 100,000 organizations worldwide",
      keywords: "",
    },
    element: <Virtuos_Attends_Bpmonline />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-attends-dreamforce-2019",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos attends Dreamforce 2019 and Gartner Event in Las Vegas – Virtuos News & Events",
      description:
        "Venky Vijay Reddi, the CEO of Virtuos, attended Dreamforce 2019 between November 19 to 22nd, 2019, and met several key Salesforce executives on Rigour's future launch, a Salesforce platform product strategy",
      keywords: "",
    },
    element: <Virtuos_Attends_Dreamforce_2019 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-attends-dreamforce-kana-connect-oracle-open-world-2012",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos | Virtuos attends Dreamforce 2012, KANA Connect 2012 and Oracle Open World 2012 in the US",
      description:
        "latest events, information on events, current events, and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events.",
      keywords: "",
    },
    element: <Virtuos_Attends_Dreamforce_2012 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-attends-gartner-summit-for-marketers-and-forrester-2016",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Attends It's Second Gartner Summit for Marketers and Forrester CXNYC – Virtuos News & Events",
      description:
        "Virtuos Team attends the second Digital Marketing Summit for Marketers by Gartners in San Diego. The event was held on May 17 for three days attracting over 1500 attendees from all over the world",
      keywords: "",
    },
    element: <Virtuos_Attends_Gartner_2016 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-attends-kana-customer-summit-in-uk",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Team members — Venky Vijay Reddi, CEO, Shaloo Reddi, EVP, and Kristina Hermanns, Director attended the Virtuos KANA Summit in Coventry, UK",
      keywords: "",
    },
    element: <Virtuos_Attends_Kana_Customer_Summit_In_Uk />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-attends-rightnow-user-conference-in-melbourne-australia",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos attends RightNow User Conference for the second time to connect and engage with key customers and partners across the APAC region",
      keywords: "",
    },
    element: <Virtuos_Attends_Rightnow_User_Conference />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-awarded-as-cx-partner-of-the-year-by-oracle",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Solutions, a Virtuos Company with India operations was recognised and awarded by Oracle Corporation as The CX Partner of the year — the most prestigious category for bringing the maximum number of logos in Asia",
      keywords: "",
    },
    element: <Virtuos_Awarded_As_Cx_Partner />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-bags-asia-biggest-ondemand-crm",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has bagged the most prestigious CRM Project from MakeMyTrip — India's leading Online Travel Company",
      keywords: "",
    },
    element: <Virtuos_Bags_Asia_Biggest_Ondemand_Crm />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-bags-crm-contract-from-leading-bpo",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "A week after its incorporation, Virtuos landed a major deal valued at US$100k, including the licensing and implementation services for KANA Response, a product Virtuos team has implemented for a leading client — Standard Chartered Bank",
      keywords: "",
    },
    element: <Virtuos_Bags_Crm_Contract_From_Leading_Bpo />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-bags-crm-service-cloud-contract-from-flipkart",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Flipkart.com, an upcoming eCommerce company, has chosen RightNow CX Suite from Virtuos to deliver superior Customer Experience (CX) to its customers",
      keywords: "",
    },
    element: <Virtuos_Bags_Crm_Service_Cloud_Contract_From_Flipkart />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-bags-orders-for-kana-and-maximizer",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has been growing with the portfolio of CRM and Customer Experience/Customer Service Solutions from leading Players — Kana, Maximizer, and RightNow",
      keywords: "",
    },
    element: <Virtuos_Bags_Orders_For_Kana_And_Maximizer />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-bags-thomas-cook-india",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos bags Thomas Cook India as its new customer, a major win over the stiff competition – Virtuos News & Events",
      description:
        "Virtuos Consultare added a new logo to its customer list by offering comprehensive Customer Experience (CX) solutions on the cloud from Oracle RightNow stable to Thomas Cook India Ltd",
      keywords: "",
    },
    element: <Virtuos_Bags_Thomas_Cook_India />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-becomes-premium-partner-rightnow-technologies",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos agreement with RightNow Technologies includes reselling of RightNow Cloud CRM Software, services, support, and implementing CRM solutions for companies that are in the region of APAC",
      keywords: "",
    },
    element: <Virtuos_Becomes_Premium_Partner />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-celebrates-its-10th-anniversary",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos celebrates its 10th Anniversary and Springs Xonomy – Virtuos News & Events",
      description:
        "The company enters a new milestone by launching Xonomy business on its 10th Anniversary. The theme of X Anniversary celebrations was Strategy is Innovation",
      keywords: "",
    },
    element: <Virtuos_Celebrates_Its_10th_Anniversary />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-celebrates-its-11th-anniversary",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos to celebrate its 11th Anniversary With the Launch of CulturO – Virtuos News & Events",
      description:
        "Culture is increasingly in the news, and it comes up in earnings calls with investors very often to assess the connection between digital strategy and organizational thinking",
      keywords: "",
    },
    element: <Virtuos_Celebrates_Its_11th_Anniversary />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-ceo-attends-dreamforce-salesforce-global-event-2014",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos CEO Attends Dreamforce, Salesforce Global Event At San Francisco – Virtuos News & Events",
      description:
        "Dreamforce has been one of the most important events that Virtuos teams attend every year without fail",
      keywords: "",
    },
    element: <Virtuos_Ceo_Attends_Dreamforce />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-clicksoftware-sign-partnership",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos and Clicksoftware sign partnership to position Field Service Cloud in India– Virtuos News & Events",
      description:
        "Virtuos, a leading CRM Software Consulting company and Clicksoftware, the market leader in Field Service Automation have joined hands to position Clicksoftware Field Service Cloud Applications to Medium and Large Enterprise customers in India",
      keywords: "",
    },
    element: <Virtuos_Clicksoftware_Sign_Partnership />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-cloudle-oracle-netsuite-form-alliance",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos | Virtuos Cloudle and Oracle NetSuite form alliance",
      description:
        "Virtuos | Virtuos Cloudle and Oracle NetSuite form alliance",
      keywords: "",
    },
    element: <Virtuos_Cloudle_Oracle />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-co-sponsors-oracle-applications-apac-partners-meet",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos | Virtuos co-sponsors Oracle Applications APAC Partners Meet at Chiang Mai Thailand Aug 1 to 3 2012",
      description:
        "latest events, information on events, current events and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events",
      keywords: "",
    },
    element: <Virtuos_Co_Sponsors_Oracle />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-co-sponsors-oracle-cloud-world-2014",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Co-Sponsors Oracle Cloud World in New Delhi and Melbourne Australia – Virtuos News & Events",
      description:
        "Virtuos, who received the CX Partner of the year recognition from Oracle for the outstanding performance participates in Oracle Cloud World as a co-sponsor",
      keywords: "",
    },
    element: <Virtuos_Co_Sponsors_Oracle_2014 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-co-sponsors-oracle-cloud-world-in-mumbai",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Solutions and Oracle are jointly working on building Customer Experience transformation services to some of India's biggest companies. Virtuos key customers- MakeMyTrip, Flipkart.com, Myntra, BookMyShow, etc. featured prominently in the press coverage of Oracle CloudWorld 2013 Mumbai",
      keywords: "",
    },
    element: <Virtuos_Co_Sponsors_Oracle_Cloud_Mumbai />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-co-sponsors-top-indian-banks-2008",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has co-sponsored India's Top Banks- 2009 in association with Dun & Bradstreet and other sponsors in the commercial capital of India — Mumbai",
      keywords: "",
    },
    element: <Virtuos_Co_Sponsors_Top_Indian_Banks_2008 />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/news/virtuos-completes-a-wonderful-7-years",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Completes Wonderful 7 Years – Virtuos News & Events",
      description:
        "Virtuos has grown year - on - year with impressive double digit performance acquiring more than 100 leading brands as the customers across the APAC region. Today on its seventh anniversary Virtuos celebrates its success with its employees and senior management",
      keywords: "",
    },
    element: <Virtuos_Completes_A_Wonderful_7_Years />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-consultare-and-oracle-consulting-services-sign-mou",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Consultare becomes the Implementation and Professional Services Provider to Oracle Consulting Services (OCS) as the latter's Single Implementation Provider for Oracle RightNow CX Cloud Service Practice",
      keywords: "",
    },
    element: <Virtuos_Consultare_And_Oracle_Mou />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-consultare-implements-oracle-rightnow-cx-in-china",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Consultare becomes the Implementation and Professional Services Provider to Oracle Consulting Services (OCS) as the latter's Single Implementation Provider for Oracle RightNow CX Cloud Service Practice",
      keywords: "",
    },
    element: <Virtuos_Consultare_Implements_China />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-creates-new-blueprint-for-fy20-growth",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Creates New Blueprint for FY20 Growth By Building Company Wide Virtuosoship – Virtuos News & Events",
      description:
        "Inspired by the power of Virtuoso teams, we launched Virtuos — A company to play by a different set of rules than other (traditional) organizations do",
      keywords: "",
    },
    element: <Virtuos_Creates_New_Blueprint />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-embraces-business-n-model",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos embraces New Digital Business Model",
      description: "Unveiling Post-COVID19 Strategy— ExperienceNew",
      keywords: "",
    },
    element: <Virtuos_Embraces_Business_N_Model />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-enveils-a-major-rebranding",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Unveils A Major Rebranding Effort That Paves the Way For Creating More Than A Customer Experience – Virtuos News & Events",
      description:
        "Virtuos Corporation is proud to unveil today its new logo and tagline - customer @ heart. The redesigned logo reflects fresh innovative business ideas, incredible transformation services and powerful engagement platforms",
      keywords: "",
    },
    element: <Virtuos_Enveils_A_Major_Rebranding />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-xonomy-invests-in-experience-economy-company",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Xonomy invests in Sweven—Salesforce Partner",
      description:
        "Virtuos Xonomy invests in Sweven Digital Private Ltd., a Salesforce-focused CRM Mastermind Consulting Company",
      keywords: "",
    },
    element: <Virtuos_Exonomy_Invests_In_Experience />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-holds-its-first-experience-executive-summit",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos holds its First Experience+ Summit creating ExperienceJob – Virtuos News & Events",
      description:
        "Virtuos and its employee strength have gathered for its Experience+ Summit at a beautiful five Star Luxury Hotel — Westin Gurgaon",
      keywords: "",
    },
    element: <Virtuos_Holds_Its_First_Experience />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-implements-clicksoftware-field-service-at-barc-and-hicare",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Implements Clicksoftware FieldService at BARC and HiCare – Virtuos News & Events",
      description:
        "Virtuos and Clicksoftware have signed a partnership to position the latter’s industry-leading Field Service Automation (FSA) to leading customers in India",
      keywords: "",
    },
    element: <Virtuos_Implements_Clicksoftware />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-implements-kana-solution-at-standard-chartered-bank",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos implements KANA Response Email Management System for Standard Chartered Bank Malaysia and in other parts of APAC including Singapore",
      keywords: "",
    },
    element: <Virtuos_Implements_Kana_Solution />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-implements-oracle-cx-at-tvs-credit-craftsvilla-india-first",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Implements Oracle CX at TVS Credit, Craftsvilla, India First – Virtuos News & Events",
      description:
        "Virtuos, a market leader in Oracle CX Technologies in India has implemented large projects at TVS Credit, Chennai based leading Automotive Financial Services Company, and part of Top 100 Corporate houses in India, Craftsvilla, venture capital-funded eCommerce company, and a leading Insurance Company based at Mumbai - India First",
      keywords: "",
    },
    element: <Virtuos_Implements_Oracle_Cx />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-implements-salesforce-at-paytm",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Implements Salesforce's First CRM Project at PayTM – Virtuos News & Events",
      description:
        "Salesforce has approached Virtuos because of its deep expertise in implementing Complete CX and CRM Suite for leading eCommerce/internet companies like Myntra, Flipkart, and Makemytrip",
      keywords: "",
    },
    element: <Virtuos_Implements_Salesforce_At_Paytm />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-incorporated-in-hyderabad",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Solutions Private Ltd. has been incorporated in India taking over the business (Not the company) from Virtuosol Consulting Group (of Tekcorp combine)",
      keywords: "",
    },
    element: <Virtuos_Incorporated_In_Hyderabad />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-india-closes-financial-year-2012",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos witnessed significant growth in Customer Experience (CX) Consulting practice with record revenue contributed by Cloud-based Application renewals and new acquisition of clients",
      keywords: "",
    },
    element: <Virtuos_India_Closes_Financial_Year_2012 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-joins-sap-partneredge-program",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Solutions, a Virtuos Company with India operations, is proud to announce its new partnership with global business software leader SAP AG (NYSE: SAP), joining the SAP® PartnerEdge™ program as an Authorized Reseller",
      keywords: "",
    },
    element: <Virtuos_Joins_Sap_Partneredge_Program />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-launches-co-extend-delivery-methodology",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Launches Co-Extend Delivery Methodology – Virtuos News & Events",
      description:
        "What is our Co-Extend Delivery Methodology? Virtuos delivers a piece of work independently and train the teams at the client side to be familiar with the development so that the entire Professional Services Project work can be shared nicely",
      keywords: "",
    },
    element: <Virtuos_Launches_Co_Extend_Delivery_Methodology />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-launches-consultare-division",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has launched Consultare division, the high performance consulting services arm within Virtuos. Virtuos has been delivering CRM Technology Consulting Services and Solutions to leading brands for the last four years",
      keywords: "",
    },
    element: <Virtuos_Launches_Consultare_Division />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-launches-innovation-open",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos, an idea hunter and business innovation leader reached new heights with tremendous growth helping next generation companies across the world - be it PartyGaming in Gibraltar, EMEA; NUS in Singapore; NZPost in NewZealand; or MakeMyTrip, Flipkart, Myntra, etc. in India",
      keywords: "",
    },
    element: <Virtuos_Launches_Innovation_Open />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-launches-new-division-yippee-media",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has announced the launch of its new division Yippee Media with ambitious goals in the New Media Industry with initial focus on building digital assets in News & Entertainment; CRM Technologies; and Digital Marketing businesses",
      keywords: "",
    },
    element: <Virtuos_Launches_New_Division_Yippee_Media />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/news/virtuos-launches-new-edition-of-website",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Launches New Website 3.1 of Website – Virtuos News & Events",
      description:
        "Virtuos, a leading Customer Experience Transformation Services and Consulting company has unveiled its latest website (www.virtuos.com)",
      keywords: "",
    },
    element: <Virtuos_Launches_New_Edition_Of_Website />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-leadership-meets-maximizer-team-in-uk",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos and Maximizer have been serving Small and Medium customers who require an integrated B2B Sales, Marketing, and Customer Service",
      keywords: "",
    },
    element: <Virtuos_Leadership_Meets_Maximizer_Team_In_Uk />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-moves-to-a-new-office-2013",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos moves to a new building certified by the Govt. of Haryana to run 24x7 operations with uninterrupted power supply and easy accessibility to Metro and Other modes of transportation",
      keywords: "",
    },
    element: <Virtuos_Moves_To_A_New_Office_2013 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-moves-to-a-new-office-in-gurgaon",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Solutions in India shifts its Corporate office to an independent and spacious office space in Gurgaon, Haryana, to accommodate 40 to 50 employees",
      keywords: "",
    },
    element: <Virtuos_Moves_To_A_New_Office_In_Gurgaon />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-moves-to-new-office-emaar-digital-greens",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Digital Moves to Brand New Office at Emaar Digital Greens in Gurugram – Virtuos News & Events",
      description:
        "Virtuos India head office is now in the prestigious Emaar Digital Greens Tower (A), a 12-acre campus offering vast greenery surrounded by the posh Golf Course Road locale and amenities",
      keywords: "",
    },
    element: <Virtuos_Moves_To_New_Office_edg />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-opens-new-office-in-mumbai",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Solutions expands with one more Office to cater to the Mumbai-WEST region closer to BPOs, BFSI, and ITES Customers in India",
      keywords: "",
    },
    element: <Virtuos_Opens_New_Office_In_Mumbai />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-oracle-cx-tour-in-delhi-mumbai",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos | Oracle and Virtuos CX Tour in Mumbai and Delhi (India)",
      description:
        "latest events, information on events, current events and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events",
      keywords: "",
    },
    element: <Virtuos_Oracle_Cx_Tour_In_Delhi_Mumbai />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-participates-in-dreamforce-2015",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Participates in Dreamforce Yet Another Time in San Francisco – Virtuos News & Events",
      description:
        "Venky Vijay Reddi attends Dreamforce 2015 in San Francisco, meeting the partners, customers, and prospects and exploring new technologies while learning about modern CRM Technologies",
      keywords: "",
    },
    element: <Virtuos_Participates_In_Dreamforce_2015 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-presents-business-through-social-at-oracle-social-crm-events",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos, the Gold certified Partner of Oracle is the only partner to showcase its presentation at the Oracle Social CRM Event held on 12th, 13th and 14th December in Delhi, Bengaluru and Mumbai",
      keywords: "",
    },
    element: <Virtuos_Presents_Business_Through_Social_At_Oracle />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-signs-partnership-with-rightnow-technologies",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos signs partnership agreement with RightNow Technologies, Inc., a Nasdaq listed leader in Business-To-Consumer CRM Solutions with over 1800 customers worldwide",
      keywords: "",
    },
    element: <Virtuos_Signs_Partnership_With_Rightnow />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-signs-two-new-deals-party-gaming-and-cashtech",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has completed its 2nd Financial Year with an impressive growth trajectory with a series of significant deals from Party Gaming, the Global Gaming Software Giant through its Indian subsidiary Ivy Comptech",
      keywords: "",
    },
    element: <Virtuos_Signs_Two_New_Deals />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-signs-up-with-gartner-india",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Gartner will provide Analyst, Advisory and Research Services to Virtuos for the latter to map out its global expansion strategy and position differentiated services across the globe",
      keywords: "",
    },
    element: <Virtuos_Signs_Up_With_Gartner_India />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-signs-up-with-maximizer",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos becomes Maximizer Business Partner and launches Maximizer CRM 10 in four variants. Maximizer UK (Max.co.uk) and Virtuos Solutions Private Ltd. (virtuos.com) have announced the partnership to launch the most innovative CRM Solutions that can work on-premise, on-private cloud, and on-public cloud",
      keywords: "",
    },
    element: <Virtuos_Signs_Up_With_Maximizer />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-signs-with-myntra.com",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Myntra.com joins the league with MakeMyTrip, Flipkart.com, Fundtech, Cleartrip, BookMyShow, and several other Customers to implement a powerful cloud-based Oracle RightNow Customer Experience Suite",
      keywords: "",
    },
    element: <Virtuos_Signs_With_Myntra />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-snaps-thomas-cook-india",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Consultare added a new logo to its customer list by offering comprehensive Customer Experience (CX) solutions on the cloud from Oracle RightNow stable to Thomas Cook India Ltd",
      keywords: "",
    },
    element: <Virtuos_Snaps_Thomas_Cook_India />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-solutions-changes-its-name-to-virtuos-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Solutions Changes its Name To Virtuos Digital – Virtuos News & Events",
      description:
        "With the new name — Virtuos Digital, we put digital at the core of everything we do in Customer Experience (CX), Business Transformation, and CRM Technology Services",
      keywords: "",
    },
    element: <Virtuos_Solutions_Changes />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-solutions-moves-to-a-new-office",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has many employees needing additional space and parking facilities for the vehicles. The previous office, unfortunately, has not been able to meet our needs",
      keywords: "",
    },
    element: <Virtuos_Solutions_Moves_To_A_New_Office />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-cx-io-gartner-marketing-summit",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has many employees needing additional space and parking facilities for the vehicles. The previous office, unfortunately, has not been able to meet our needs",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Cx_Io_Gartner_Marketing_Summit />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-dreamforce-2013",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Team comprising Venky Vijay Reddi, CEO, Kristina Hermanns, Director — Solution Consulting and Business Head - ExperienceCloud attended the world’s largest technology event — Dreamforce",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Dreamforce_2013 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-dreamforce-2017",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Team Attends Dreamforce'17 in San Francisco – Virtuos News & Events",
      description:
        "This being the fifth consecutive dreamforce for Virtuos CEO to attend Dreamforce in person to witness the transformative CRM Technologies and Industry trends catapulted by the Fortune CEOs and visionary leaders",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Dreamforce_2017 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-gartner-application-strategies-solutions-summit-2018",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Team Attends Gartner Application Strategies and Solutions Summit in Las Vegas – Virtuos News & Events",
      description:
        "One of Gartner's key summits, The Application Strategies, and Solutions Summit 2018 (ASSS 2018), was held at Caesars Palace, Las Vegas, between Nov 27-29, 2018",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Gartner_2018 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-gartner-digital-marketing-symposium-2019",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Team Attends Gartner Marketing XPO at San Diego – Virtuos News & Events",
      description:
        "Virtuos CEO — Venky Vijay Reddi attended Gartner’s Digital Marketing Symposium/XPO 2019 for the fifth time since 2015 consecutively",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Gartner_2019 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-oracle-apac-partner-summit-2015",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Team Attends Oracle APAC Partner Summit in Bangkok – Virtuos News & Events",
      description:
        "Virtuos CEO, along with Executive VP Shaloo Reddi, Director Solution Consulting Kristina Hermanns, attend Oracle Partner (APAC) Annual Summit in Bangkok",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Oracle_2015 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-oracle-open-world-at-moscone-center-san-francisco",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos, a gold certified Partner and Leader in CX Practice across has witnessed increase in the attendance to Oracle Open World this year",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Oracle_San_Francisco />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-rightnow-apac-summit-in-austraila",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Leadership team members headed by Venky Vijay Reddi, the CEO attended the RightNow APAC summit held at Gold Coast, Sydney",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Rightnow_Austraila />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-team-attends-salesforce-conference-2016",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Team Attends Salesforce Conference – Dreamforce 2016 in San Francisco – Virtuos News & Events",
      description:
        "Dreamforce has representation from over 100 leading software and technology vendors with their products and services. Salesforce.org and nonprofit and higher education institutions worldwide",
      keywords: "",
    },
    element: <Virtuos_Team_Attends_Salesforce_Conference_2016 />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/news/virtuos-teams-undergo-maximizer-crm-10-training",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos Teams undergo Maximizer CRM 10 Training at its New Delhi headquarters covering Sales, Service, and Marketing Modules of a new version",
      keywords: "",
    },
    element: <Virtuos_Teams_Undergo_Maximizer_Crm />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-to-enter-in-ecommerce-sector-with-giftcart.com",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos, through its Tekcorp VC Portfolio, is planning to invest almost US$1 Million over the next 2-3 years in Giftcart.com, an in-house brand at Virtuos",
      keywords: "",
    },
    element: <Virtuos_To_Enter_In_Ecommerce />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-to-focus-on-global-expansion-in-new-fiscal-year",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos to Focus on Global Expansion – Virtuos News & Events",
      description:
        "Virtuos, a leading Customer Experience Transformation Services and Consulting company has been on expansion spree bringing new talent and venturing into new geographies across APAC, EMEA and America",
      keywords: "",
    },
    element: <Virtuos_To_Focus_On_Global_Expansion />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-to-implement-maximizer-crm",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description:
        "Virtuos has bagged prestigious orders from multiple customers in India during the last two quarters. One of them being the world's largest independent specialty manufacturer with global offices",
      keywords: "",
    },
    element: <Virtuos_To_Implement_Maximizer_Crm />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-to-unveil-its-first-product-strategy-aury",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos To Unveil it's Product Strategy: AURY Chatbot – Virtuos News & Events",
      description:
        "Virtuos, a leading CRM and CX Consulting company announces its readiness to launch Aury® — AI rich conversational pervasive Experience Cloud Platform using patented NLP",
      keywords: "",
    },
    element: <Virtuos_To_Unveil_Its_First_Product_Strategy_Aury />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-to-unveil-NOVV",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos to unveil NOVV — NewWe Digital Transformation Practice – Virtuos News & Events",
      description:
        "Virtuos to unveil NOVV — NewWe Digital Transformation Practice",
      keywords: "",
    },
    element: <Virtuos_To_Unveil_NOVV />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-digital",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Solutions Changes its Name To Virtuos Digital – Virtuos News & Events",
      description:
        "With the new name — Virtuos Digital, we put digital at the core of everything we do in Customer Experience (CX), Business Transformation, and CRM Technology Services",
      keywords: "",
    },
    element: <Virtuos_digital />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/USA-Trip-2023",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "USA-Trip-2023",
      description: "",
      keywords: "",
    },
    element: <Virtuos_USA_Trip />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-smartsheet",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos-Smartsheet",
      description: "",
      keywords: "",
    },
    element: <Virtuos_Smartsheet />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-veracis-launches-cxdesk-expedience",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Veracis launches CXDesk 2.0 and Expedience Talent Advisory",
      description:
        "Virtuos Veracis announces CXDesk 2.0 and soon will be launching Expedience Advisory Services by including Talentare",
      keywords: "",
    },
    element: <Virtuos_Veracis_Launches_Cxdesk_Expedience />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-verint-sign-partnership-to-bring-cx-ecosystems",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos and Verint Sign Partnership To Bring CX Ecosystems to Key Enterprise Customers – Virtuos News & Events",
      description:
        "Verint has taken full control of Brand KANA and its assets to run it as part of its Customer Engagement Practice using its Actionable Intelligence edge",
      keywords: "",
    },
    element: <Virtuos_Verint_Sign_Partnership />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-vivacis-global-projects",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Vivacis Wins New Projects From Global Customers – Virtuos News & Events",
      description:
        "Virtuos Vivacis continues to operate at its PEAK, with a number of resources that are fully qualified and prepared to manage global projects involving digital transformation, including business automation, & CRM",
      keywords: "",
    },
    element: <Virtuos_Vivacis_Global_Projects />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-vivacis-implements-CLM",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Vivacis implements CLM for New Clients – Virtuos News & Events",
      description:
        "Vivacis is a new division kicked off last quarter to enter into the burgeoning space of Digital Process Automation (DPA), Quote to Cash, Configure Price Quote (CPQ) Automation, Robotics Process Automation and Contract Lifecycle Management (CLM)",
      keywords: "",
    },
    element: <Virtuos_Vivacis_Implements_CLM />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-vivacis-oracle-netsuite-form-alliance",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Vivacis and Oracle NetSuite Form Alliance to Introduce the World's Most Cloud Deployed ERP – Virtuos News & Events",
      description:
        "Virtuos unveils Vivacis, a new brand under the umbrella of Virtuos as a Strategic Business Unit (SBU) to cater to Small & Medium sized businesses (SMB) offering Cloud First, and Digital First Applications, Cloud based solutions, and Cloud professional services",
      keywords: "",
    },
    element: <Virtuos_Vivacis_Oracle_Netsuite_Form_Alliance />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/virtuos-vlocis-implements-CLM",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos Vivacis implements CLM for new clients",
      description: "",
      keywords: "",
    },
    element: <Virtuos_Vlocis_Implements_CLM />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/events",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos News & Events",
      description: "",
      keywords: "",
    },
    element: <Events />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/events/virtuos-cx-summit-2016",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Latest Events | Information on Events - Virtuos",
      description:
        "latest events, information on events, current events and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events.",
      keywords: "",
    },
    element: <Virtuos_Cx_Summit_2016 />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/seismicity",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Governance, Risk, and Compliance (GRC) Business – Virtuos Seismicity",
      description:
        "Virtuos enters Governance, Risk, and Compliance (GRC) Business with Seismicity as a new Business Unit",
      keywords: "",
    },
    element: <Seismicity />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/salesforce",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Virtuos and Salesforce | CRM Mastermind – Virtuos Rigour",
      description:
        "Virtuos springs a new Strategic Business Unit (SBU) — Rigour to focus on Salesforce Ecosystem of Products and Services.",
      keywords: "",
    },
    element: <Salesforce />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/coming-soon",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title: "Coming Soon",
      description: "",
      keywords: "",
    },
    element: <ComingSoon />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/16th-anniversary",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Celebrates 16th Anniversary in Shimla, India's Scenic Hill Station",
      description:
        "latest events, information on events, current events and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events.",
      keywords: "",
    },
    element: <Anniversary_16th />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/dreamfox",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "  Virtuos Unveils New Superpower: Introducing Dreamfox for Digital Marketing Excellence",
      description:
        "latest events, information on events, current events and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events.",
      keywords: "",
    },
    element: <Dreamfox />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/skillnow",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Virtuos Acquires SkillNow: Transforming into an Agile Learning Platform",
      description:
        "latest events, information on events, current events and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events.",

      keywords: "",
    },
    element: <Skillnow />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/fugxnfi7kpkIgQg+T6T5xQ",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "",
      description:
        "",

      keywords: "",
    },
    element: <Leads />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/news/cohering",
    loader() {
      return json({
        message:
          "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
      });
    },
    handle: {
      title:
        "Cohering, a suite of AI-Platforming tools designed to build Composite AI by integrating AI techniques.",
      description:
        "latest events, information on events, current events and information at Virtuos. All the list of important events at Virtuos. Partner events, customer events, employee events.",

      keywords: "",
    },
    element: <Cohering />,
    errorElement: <PageNotFound />,
  },

  // {
  //   path: "*",
  //   loader() {
  //     return json({
  //       message:
  //         "Virtuos | High performance Digital Transformation Consulting Services Virtuos Digital Ltd.",
  //     });
  //   },
  //   element: (
  //     <Page title: "Page Not Found" description: "" keywords: "">
  //       <PageNotFound />
  //     </Page>
  //   ),
  //   errorElement: <PageNotFound />,
  // },
];

export default routes;
