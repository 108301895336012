"use client";
import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
    FaRobot,
    FaCommentDots,
    FaPaperPlane,
    FaTimes,
    FaSpinner,
    FaEnvelope,
} from "react-icons/fa";
import axios from "axios";
import styles from "./chatbot.module.css";

const BotAvatar = () => (
    <div className={styles.botAvatar}>
        <img src="/img/aury_icon.svg" alt="bot_logo" style={{ width: "2rem", height: "2rem", color: "#00a1fe" }} />
    </div>
);

const ContactFallback = () => (
    <div className={styles.contactFallback}>
        <p>
            I apologize, but I'm not sure how to help with that request. You can reach
            us through:
        </p>
        <a href="mailto:info@virtuos.com" className={styles.contactLink}>
            <FaEnvelope style={{ width: "1rem", height: "1rem" }} />
            <span>info@virtuos.com</span>
        </a>
        <a href="https://www.virtuos.com/contact" className={styles.contactButton}>
            Contact Form
        </a>
    </div>
);

const Chatbot = () => {
    const [isChatOpen, setIsChatOpen] = useState(true);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([
        {
            id: 1,
            text: "Hi there! I'm your AI assistant. Before we begin, could you please tell me your name?",
            sender: "bot",
        },
    ]);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSendMessage = async () => {
        if (message.trim()) {
            const userMessage = {
                id: messages.length + 1,
                text: message,
                sender: "user",
            };
            setMessages((prevMessages) => [...prevMessages, userMessage]);

            setIsLoading(true);

            try {
                const response = await axios.post("https://api.virtuos.com/chat", {
                    message,
                });
                const intent = response.data.result.intent;

                let botResponse;
                if (intent === "Default Fallback Intent") {
                    botResponse = {
                        id: messages.length + 2,
                        text: <ContactFallback />,
                        sender: "bot",
                    };
                } else {
                    botResponse = {
                        id: messages.length + 2,
                        text: response.data.result.bot,
                        sender: "bot",
                    };
                }

                setMessages((prevMessages) => [...prevMessages, botResponse]);
            } catch (error) {
                const errorMessage = {
                    id: messages.length + 2,
                    text: "Sorry, there was an error processing your message. Please try again.",
                    sender: "bot",
                };

                console.error("Chat error:", error);
                setMessages((prevMessages) => [...prevMessages, errorMessage]);
            } finally {
                setIsLoading(false);
                setMessage("");
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSendMessage();
        }
    };

    return (
        <div className={styles.chatbotContainer} id="chatbot">
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setIsChatOpen(!isChatOpen)}
                className={styles.chatToggleButton}
            >
                {isChatOpen ? (
                    <FaTimes className={styles.icon} />
                ) : (
                    <FaCommentDots className={styles.icon} />
                )}
            </motion.button>

            <AnimatePresence>
                {isChatOpen && (
                    <motion.div
                        initial={{
                            opacity: 0,
                            scale: 0.8,
                            transformOrigin: "bottom right",
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            rotate: [0, -2, 2, -1, 1, 0],
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 250,
                            damping: 15,
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0.8,
                            transformOrigin: "bottom right",
                        }}
                        className={styles.chatWindow}
                    >
                        <div className={styles.chatHeader}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <BotAvatar />
                                <div className={styles.botInfo}>
                                    <h3 className={styles.botTitle}>AI Assistant</h3>
                                    <p className={styles.botStatus}>Online and ready to help</p>
                                </div>
                            </div>
                            <motion.button
                                whileHover={{ rotate: 90 }}
                                onClick={() => setIsChatOpen(false)}
                                className={styles.closeButton}
                            >
                                <FaTimes style={{ color: "white" }} size={20} />
                            </motion.button>
                        </div>

                        <div className={styles.messagesContainer}>
                            {messages.map((msg) => (
                                <motion.div
                                    key={msg.id}
                                    initial={{ opacity: 0, x: msg.sender === "bot" ? -20 : 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                                    className={`${styles.messageBox} ${msg.sender === "bot"
                                        ? styles.botMessage
                                        : styles.userMessage
                                        }`}
                                >
                                    {msg.sender === "bot" && <BotAvatar />}
                                    <div
                                        className={
                                            msg.sender === "bot"
                                                ? styles.botMessageContent
                                                : undefined
                                        }
                                    >
                                        {msg.text}
                                    </div>
                                </motion.div>
                            ))}

                            {isLoading && (
                                <div className={styles.loadingContainer}>
                                    <BotAvatar />
                                    <div className={styles.botMessageContent}>
                                        <FaSpinner className={styles.loadingSpinner} />
                                        <span>Typing...</span>
                                    </div>
                                </div>
                            )}

                            <div ref={messagesEndRef} />
                        </div>

                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder="Type your message..."
                                className={styles.messageInput}
                            />
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={handleSendMessage}
                                disabled={!message.trim()}
                                className={styles.sendButton}
                            >
                                <FaPaperPlane />
                            </motion.button>
                        </div>
                        <div className={styles.licensed}>
                            <p style={{ fontSize: "0.8rem", textAlign: "center", color: "#666" }}>
                                Licensed by{" "}
                                <img src={"/img/saathi_logo_TM.svg"}/>
                            </p>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Chatbot;
