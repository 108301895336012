import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Terms_Of_Use = () => {
  useEffect(() => {
    document.title = "Website Terms and Conditions of Use – Virtuos Digital";
  }, []);
  return (
    <div className="term-condition-page listing-page">
      <Navbar />
      <main className="main-wrapper">
        <>
          <section className="top-hero-product-banner" >
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/svg/terms-condition-banner-img.svg`}
                      alt="Terms of Use - Virtuos"
                    />
                  </figure>
                </div>
                <div className="col-6">
                  <h3 className="section-heading">
                    <span className="highlight" style={{ lineHeight: "normal" }}>Terms </span>of Use for Virtuos Corporation, Associates &amp; Their Subsidiaries
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="legal-section" >
            <div className="page-wrap legal-wrapper">
              <div className="legal-doc-wrapper">
                <span className="date">Last updated January 7, 2025</span>

                <h2 className="section-heading">1. Introduction</h2>
                <p>
                  Welcome to Virtuos. These Terms of Use (“Terms”, “Terms of Service”, “Agreement”) apply
                  to the Virtuos website (“website”, “site”, “sites”) located at www.virtuos.com and its
                  associated links, owned and operated by Virtuos Corporation in the United States, Virtuos
                  Digital Limited in India, and other subsidiaries worldwide (collectively “Virtuos”, “we”,
                  “our”, “us”). By accessing or using this site, you agree to these Terms. If you do not agree,
                  you must refrain from using the site.
                </p>
                <h2 className="section-heading">2. Changes to Terms and Website</h2>
                <p>
                  Virtuos reserves the right to modify these Terms of Use and the content of our website at any
                  time without prior notice. Continued use of the site after any changes signifies acceptance of
                  the updated Terms. It is your responsibility to review these Terms periodically for updates.
                </p>
                <h2 className="section-heading">3. Content and Intellectual Property</h2>
                <p>
                  All content on this site, including but not limited to images, logos, text, videos, audio files,
                  user interfaces, code, and other materials, is owned or licensed by Virtuos. Unauthorized use,
                  reproduction, distribution, or transmission of any content is prohibited. You may download a
                  single copy of content for personal, non-commercial use, provided all proprietary notices are
                  intact.
                </p>
                <h2 className="section-heading">4. Permitted Use of the Site</h2>
                <p>
                  You agree not to:
                  <ul>
                    <li>Use automated tools such as bots, crawlers, or scrapers to access, monitor, or copy content on the site.</li>
                    <li>Attempt unauthorized access to Virtuos’ systems or networks.</li>
                    <li>Probe, scan, or test the security of the site or interfere with its operation.</li>
                    <li>Post or upload any content unless you have the necessary rights or permissions to do so.</li>
                  </ul>
                  Failure to comply with these rules may result in suspension or termination of access to the site.
                </p>
                <h2 className="section-heading">5. Indemnification</h2>
                <p>
                  You agree to indemnify and hold harmless Virtuos, its affiliates, employees, and agents from
                  any claims, liabilities, damages, losses, or expenses (including legal fees) arising out of your
                  use of the site, violation of these Terms, or infringement of any rights of a third party.
                </p>
                <h2 className="section-heading">6. Privacy and Security</h2>
                <p>
                  Your use of the site is subject to Virtuos’ Privacy Policy, which outlines how we collect, use,
                  and protect your data. By using the site, you acknowledge that no data transmission over the
                  Internet is fully secure and accept the risks associated with such transmissions.
                </p>
                <h2 className="section-heading">7. Disclaimer of Warranties</h2>
                <p>
                  The site and its content are provided “AS IS” and “AS AVAILABLE” without warranties of
                  any kind, whether express or implied. Virtuos disclaims all warranties, including but not
                  limited to merchantability, fitness for a particular purpose, and non-infringement. We do not
                  guarantee uninterrupted access, error-free functionality, or that defects will be corrected.
                </p>
                <h2 className="section-heading">8. Limitation of Liability</h2>
                <p>
                  To the maximum extent permitted by law, Virtuos shall not be liable for any indirect,
                  incidental, special, or consequential damages, including loss of profits, data, or business
                  opportunities, arising from your use or inability to use the site or its content, even if Virtuos
                  has been advised of the possibility of such damages.
                </p>
                <h2 className="section-heading">9. Compliance with International Laws</h2>
                <p>
                  Virtuos operates globally and complies with applicable laws in the jurisdictions where it
                  operates. By accessing the site, you agree to comply with all relevant local, national, and
                  international laws. Some content or services on the site may not be available in certain
                  regions due to regulatory restrictions.
                </p>
                <h2 className="section-heading">10. Artificial Intelligence and Automation Features</h2>
                <p>
                  Virtuos leverages artificial intelligence (AI) and advanced automation tools to enhance user
                  experience and site functionality. By using the site, you agree that AI-driven features may
                  collect and process data as outlined in our Privacy Policy. These tools are designed to
                  optimize performance but are not guaranteed to be error-free or fully secure.
                </p>
                <h2 className="section-heading">11. Violation of Terms</h2>
                <p>
                  Virtuos reserves the right to terminate or restrict access to the site without notice if you
                  violate these Terms or engage in unauthorized activities. Such termination does not waive our
                  right to pursue additional remedies permitted by law.
                </p>
                <h2 className="section-heading">12. Governing Law and Jurisdiction</h2>
                <p>
                  These Terms shall be governed by and construed in accordance with the laws of the United
                  States. Disputes arising out of or related to these Terms shall be resolved in the courts of the
                  jurisdiction where Virtuos Corporation is headquartered unless otherwise specified.
                </p>
                <h2 className="section-heading">13. Feedback and Submissions</h2>
                <p>
                  Any feedback, suggestions, or ideas submitted through the site shall be deemed non-
                  confidential and may be used by Virtuos without restriction or obligation to compensate you.
                </p>
                <h2 className="section-heading">14. Contact Information</h2>
                <p>
                  For inquiries regarding these Terms of Use, please contact us at:
                  <NavLink to="mailto:info@virtuos.com" className="link">info@virtuos.com</NavLink>
                  <br />
                  Mailing Address:
                  <br />
                  Virtuos Corporation, <i>
                    Suite 203, 2880 Zanker Road San Jose CA 95134 USA </i>
                </p>
                <p>
                  By using this site, you acknowledge that you have read, understood, and agreed to these
                  Terms of Use.
                </p>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </main>
    </div>
  );
};

export default Terms_Of_Use;
