import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Nav from '../components/Nav';

const Safe_Harbour = () => {
  useEffect(() => {
    document.title = "Website Safe harbor Statement – Virtuos Digital";

  }, []);
  return (
    <div className='client-detail-page landing-page'>
      <div className='canvas'>
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-6">
                  <h3 className="section-heading">
                    Safe Harbour <span className="highlight">Statement</span>
                  </h3>
                </div>
                <div className="col-6">
                  <figure>
                    <img
                      src={`/img/svg/safe-harbour-banner-img.svg`}
                      alt="Safe Harbour Statement - Virtuos"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="legal-section">
            <div className="page-wrap legal-wrapper">
              <div className="legal-doc-wrapper">
                <span className="date"> Safe Harbour Statement
                  Last updated January 7, 2025</span>
                <p>
                  This communication contains forward-looking statements within the meaning of applicable
                  securities laws. These statements include, but are not limited to, projections, expectations,
                  beliefs, plans, and assumptions regarding future business performance, growth strategies,
                  industry trends, and other aspects of Virtuos Corporation’s operations.
                </p>
                <p>
                  Forward-looking statements are inherently subject to risks and uncertainties that could cause
                  actual results to differ materially from those expressed or implied. Factors that may impact
                  these results include changes in market conditions, competitive pressures, technological
                  advancements, regulatory developments, and unforeseen economic conditions.

                </p>
                <p>
                  Virtuos Corporation undertakes no obligation to update or revise any forward-looking
                  statements to reflect changes in circumstances, events, or expectations, except as required by
                  law. Readers are cautioned not to place undue reliance on these statements, which are based
                  on current assumptions and estimates as of the date of this communication.
                </p>
                <p>
                  For additional information about risks and uncertainties, please refer to the latest disclosures
                  available on our website or in relevant corporate filings.
                </p>



              </div>
            </div>
          </section>
          <Footer />
        </main>

      </div>
      <div className='off-canvas'>
        <Nav />
      </div>
    </div>
  )
}

export default Safe_Harbour