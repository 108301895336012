import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../components/Navbar';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Privacy_Policy = () => {
  useEffect(() => {
    document.title = "Website Privacy Policy Statement – Virtuos Digital";

  }, []);
  return (
    <div className='client-detail-page landing-page'>
      <div className='canvas'>
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-6">
                  <h3 className="section-heading">
                    Virtuos Privacy <span className="highlight">Policy</span>
                  </h3>
                </div>
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/svg/privacy-policy-banner-img.svg`}
                      alt="Virtuos Privacy Policy"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="legal-section">
            <div className="page-wrap legal-wrapper">
              <div className="legal-doc-wrapper">
                <span style={{ fontWeight: "bold" }} className="date">Last updated as of January 7, 2025</span>
                <p>
                  This Privacy Policy outlines the privacy practices of Virtuos (referred to as "we," "us," or
                  "our") concerning the use of your personal information across our websites, apps, and
                  services.
                </p>
                <p>
                  By interacting with Virtuos websites, applications, or other services, you consent to the
                  practices described in this policy.
                </p>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">Scope of the Privacy Policy</h2>
                <p>This policy applies to:</p>
                <ul className="level-one">
                  <li><strong>Virtuos Websites and Web-Based Services/Applications</strong>: Including but not limited to corporate sites, client portals, and mobile applications.</li>
                  <li><strong>Virtuos Marketing, Sales, and Advertising Activities</strong>: Covering campaigns, communications, and promotions.</li>
                  <li><strong>Data Shared on Virtuos Platforms</strong>: Information stored or processed in Virtuos apps or websites, including personal information of other individuals, must be shared only if you are legally authorized to do so.</li>
                </ul>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">1. Collection of Information</h2>
                <p>Virtuos collects personal information through various interactions, such as:</p>
                <ul className="level-one">
                  <li><strong>Forms</strong>: Contact Us, Corporate Event Registration, and Job Application Forms.</li>
                  <li><strong>Cookies and Web Beacons</strong>: Tracking website interactions and email activity.</li>
                  <li><strong>Third-Party Platforms</strong>: Information shared through social media or other integrated services.</li>
                </ul>
                <p style={{ fontWeight: "bold" }}>Information Collected Includes:</p>
                <ul className="level-one">
                  <li>Full Name</li>
                  <li>Email Address</li>
                  <li>Mobile Number</li>
                  <li>Company/Organization</li>
                  <li>Job Title/Designation</li>
                  <li>Message Content (for communication)</li>
                  <li>IP Address</li>
                  <li>Browser and Device Type</li>
                  <li>Search Terms (that led to our websites)</li>
                  <li>Usage Data (navigation and interactions on websites and apps)</li>
                </ul>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">2. Use of Information</h2>
                <p>Virtuos uses your personal information for the following purposes:</p>
                <ul className="level-one">
                  <li><strong>Service Enablement</strong>: To register you with Virtuos and provide requested services or products.</li>
                  <li><strong>Enhancing User Experience</strong>: To analyze search queries, improve website/app functionality, and personalize content.</li>
                  <li><strong>Internal Improvements</strong>: Conducting audits, research, and data analysis to enhance products and services.</li>
                  <li><strong>Marketing Communications</strong>: Sending marketing materials and promotional emails, with the option to unsubscribe at any time.</li>
                  <li><strong>Responding to Inquiries</strong>: Addressing questions or requests via email or other communication channels.</li>
                  <li><strong>Social Platform Interaction</strong>: Engaging with users on third-party platforms.</li>
                  <li><strong>Cross-Border Data Transfers</strong>: Sharing information across national borders as permitted by applicable laws.</li>
                </ul>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">3. Emails from Virtuos</h2>
                <p>Emails sent by Virtuos may include tracking technologies (e.g., web beacons) to monitor engagement. If you prefer not to receive these emails:</p>
                <ul className="level-one">
                  <li><strong>Opt-out</strong> using instructions provided in every Virtuos marketing email.</li>
                </ul>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">4. Sensitive Information</h2>
                <p>Please refrain from sharing sensitive personal data with Virtuos, including but not limited to:</p>
                <ul className="level-one">
                  <li>Government-issued IDs</li>
                  <li>Financial account numbers</li>
                  <li>Racial or ethnic information</li>
                  <li>Political opinions or religious beliefs</li>
                  <li>Genetic or biometric data</li>
                  <li>Health or criminal records</li>
                </ul>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">5. Security of Information</h2>
                <p>We prioritize the security of your personal information through:</p>
                <ul className="level-one">
                  <li><strong>Technical, Administrative, and Physical Safeguards</strong>: Measures to protect data against unauthorized access, loss, or misuse.</li>
                  <li>

                    However, no security system is foolproof, and Virtuos cannot guarantee absolute security.
                  </li>
                </ul>

                <h2 style={{ fontWeight: "bold" }} className="section-heading">6. Changes to the Privacy Policy</h2>
                <p>Virtuos may update this policy periodically to reflect changes in practices or legal requirements:</p>
                <ul className="level-one">
                  <li><strong>New Users</strong>: The updated policy takes effect immediately upon posting.</li>
                  <li><strong>Existing Users</strong>: Significant changes will take effect 30 days after posting.</li>
                </ul>
                <p>You are encouraged to review this policy regularly for updates.</p>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">7. Published Content</h2>
                <p>Virtuos may publish the following on its websites:</p>
                <ul className="level-one">
                  <li><strong>Content</strong>: Photographs, videos, quotes, and other media.</li>
                  <li><strong>Sources</strong>: Publicly available materials or content shared with consent.</li>
                </ul>
                <p style={{ fontWeight: "bold" }}>Opt-Out Option:</p>
                <p>If you do not wish for your content to appear on our website, please contact <b>
                  info@virtuos.com
                </b>. We will address such requests and remove content within <b>30 business days</b> of notification.</p>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">8. Your Rights</h2>
                <p>Depending on your jurisdiction, you may have the following rights concerning your personal data:</p>
                <ul className="level-one">
                  <li><strong>Access</strong>: Request access to the information we hold about you.</li>
                  <li><strong>Correction</strong>: Update or correct inaccurate or incomplete data.</li>
                  <li><strong>Deletion</strong>: Request deletion of your data under certain conditions.</li>
                  <li><strong>Objection</strong>: Object to the processing of your personal data for specific purposes.</li>
                  <li><strong>Data Portability</strong>: Request a copy of your personal data in a structured, electronic format.</li>
                </ul>
                <h2 style={{ fontWeight: "bold" }} className="section-heading">Contact Us</h2>
                <p>For questions or concerns regarding this Privacy Policy or your personal information, please contact us at:</p>
                <p><strong>Email</strong>: <a href="mailto:info@virtuos.com">info@virtuos.com</a></p>
                <p><strong>Phone</strong>: +1 415 800 4088</p>
                <p>This version includes the latest privacy developments, emphasizes user rights, and ensures compliance with evolving regulations. Let me know if you need further refinements!</p>
              </div>
            </div>
          </section>
          <Footer />
        </main>

      </div>
      <div className='off-canvas'>
        <Nav />
      </div>
    </div>
  )
}

export default Privacy_Policy
