import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";



const Dreamfox = () => {
  useEffect(() => {
    document.title =
      "  Virtuos Unveils New Superpower: Introducing Dreamfox for Digital Marketing Excellence";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}/img/dreamfoxbanner.webp`}
                  alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Seattle January 7, 2025</span>
                <h2 className="article-heading">
                  Virtuos Unveils New Superpower: Introducing Dreamfox for Digital Marketing Excellence
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  January 18, 2025 – On the momentous occasion of Virtuos' anniversary, the company proudly will announce the launch of its new digital marketing powerhouse: Dreamfox. As a fractal unit of Virtuos Inc., Dreamfox promises to revolutionize the marketing landscape by leveraging AI-driven strategies, modern marketing models, and cutting-edge digital tools to deliver superior value to customers worldwide.
                </p>

                {/* <p>
                  Virtuos NOVV will be officially launched in the first week of
                  April 2023 in India followed by the USA and rest of the
                  geographies.
                </p> */}
                <h3 className="title">Dreamfox: The Future of Marketing, Powered by AI</h3>
                <p>
                  Dreamfox is set to redefine digital marketing by embracing the power of artificial intelligence to drive impactful results. By integrating advanced AI technologies, Dreamfox will optimize E-Commerce campaigns, Demand Generation, Digital Campaigns, and Customer Journeys, seamlessly catering to both B2B and B2C audiences. The platform's AI capabilities will enable brands to achieve precision targeting, predictive analytics, and personalized engagements that resonate with their customers.
                </p>

                <h3 className="title">A Synergistic Partnership with Virtuos Digital</h3>
                <p>
                  As part of the Virtuos family, Dreamfox complements the high-performance consulting expertise of Virtuos Digital (vdc.com), which specializes in Customer Experience (CX), CRM, CLM, and Digital Automation. Together, Virtuos and Dreamfox form a formidable combination, delivering holistic solutions that empower businesses to scale, engage, and innovate.
                </p>
                <p>
                  “Dreamfox is one of my biggest dream projects this year,” says Venky Vijay Reddi, CEO of Virtuos Inc. “Brands are struggling to fully harness AI, often limiting their efforts to basic tools like ChatGPT for content moderation. With Dreamfox, we aim to disrupt marketing by building advanced AI models that align with our AI Ambition and deliver transformative value.”
                </p>

                <h3 className="title">Global Solutions for Modern Marketing Challenges</h3>
                <p>
                  Dreamfox is designed to address the evolving needs of global businesses in the digital age. It will focus on delivering robust marketing solutions for industries ranging from e-commerce to technology, with services tailored to drive:
                </p>

                <ul>
                  <li>Demand Generation: AI-driven insights for identifying and converting high-potential leads.</li>
                  <li>Digital Campaigns: High-impact campaigns designed for maximum engagement and ROI.</li>  <li>Customer Journeys: Personalized and data-backed pathways that elevate the customer experience.</li>
                </ul>
                <h3 className="title">Dreamfox.ai: Building Modern Marketing Practices</h3>
                <p>
                  With the launch of Dreamfox.ai, Virtuos is building one of the most advanced platforms for digital marketing innovation. The initiative seeks to attract top talent specialized in cutting-edge marketing principles, digital automation, and AI-powered customer journeys.
                </p>
                <h3 className="title">Join the Dreamfox Movement</h3>

                <p>
                  Dreamfox represents a bold step forward for Virtuos Inc. and its customers. As a company deeply committed to innovation, Virtuos is setting new benchmarks in digital marketing and customer experience. This milestone launch marks the beginning of a new era, where brands and businesses can achieve unparalleled marketing success with the power of Dreamfox.
                </p>

                <p>Stay tuned for more updates as Dreamfox reshapes the digital marketing landscape! Visit <a style={{fontWeight:"bold"}} href="https://www.dreamfox.com" target="_blank" rel="noopener noreferrer">Dreamfox.ai</a>{" "}

                  for more information.</p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div >
  );
};

export default Dreamfox;
