import React, { useEffect } from "react";
import Topheader from "./Topheader";
import Main_nav_wrapper from "./Main_nav_wrapper";
import Logowrapper from "./Logowrapper";
import Sidebar from "../pages/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import Chatbot from "./Chatbot";
const Navbar = () => {
  // const ScrollToTop = () => {
  //   const { pathname } = useLocation();
  //   const prevPathnameRef = React.useRef();

  //   useEffect(() => {
  //     if (prevPathnameRef.current !== pathname) {
  //       window.scrollTo(0, 0);
  //     }
  //     prevPathnameRef.current = pathname;
  //   }, [pathname]);

  //   return null;
  // };


  return (
    <header className="header-wrapper">
      {/* <ScrollToTop /> */}
      <Topheader />
      <div className="main-header">
        <div className="page-wrap">
          <div className="row">
            <Logowrapper />
            <Main_nav_wrapper />
            {/* <Sidebar /> */}
            <Chatbot />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
